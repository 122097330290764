import classNames from "classnames";
import { max, min, toNumber, values } from "lodash";
import { cloneElement, FC, useCallback, useMemo, useState } from "react";
import { HorizontalBarChart } from "../../components/charts/bar-chart";
import { HeatMap } from "../../components/charts/heat-map";
import { QuartileChart } from "../../components/charts/quartile-chart";
import { DomainRadarChart } from "../../components/charts/radar-chart";
import { ScatterPlotWithBuckets } from "../../components/charts/scattered-chart";
import { ClassNames } from "../../components/classes";
import { Dropdown, getDropdownItem } from "../../components/dropdown";
import { Filter, getFilterConditions, useFilter } from "../../components/filter";
import { Icons } from "../../components/icons";
import { Loading } from "../../components/loading";
import { InternalPage } from "../../components/page";
import { InternalRoutes } from "../../config/routes";
import { AnalyticsType, Brand, Conditions, useGetBrandAnalyticsQuery, useGetPriceAnalyzerChartsQuery, useGetProductAttributesQuery } from "../../generated/graphql";
import { useAppSelector } from "../../store/hooks";
import { formatToIndianNumbering, toTitleCase } from "../../utils/functions";
import { EmbedProductList, useEmbedProductList } from "../product/embed-product-list";

const QuartileBrandSection: FC<{ index: number, brand: Brand; yMax: number; yMin: number; conditions: Conditions; updateBrandMap: (brandId: string, count: number, range: [number, number]) => void }> = ({
    index,
    brand,
    conditions,
    updateBrandMap,
    yMin,
    yMax,
}) => {
    const { data: analytics, loading } = useGetBrandAnalyticsQuery({
        variables: {
            brandId: brand?.Id,
            conditions,
        },
        onCompleted(data) {
            if (data.BrandAnalytics != null) {
                const totalProductsValue = data.BrandAnalytics.find(analytic => analytic.Type === AnalyticsType.Highlight && analytic.Title === "Total Products")?.Highlight?.Value;
                const quartileValue = data.BrandAnalytics.find(analytic => analytic.Type === AnalyticsType.QuartileChart)?.Quartile;
                if (totalProductsValue != null && quartileValue != null) {
                    updateBrandMap(brand.Id, toNumber(totalProductsValue), [quartileValue.Min, quartileValue.Max]);
                }
            }
        },
    });

    const quartileAnalytics = useMemo(() => {
        return analytics?.BrandAnalytics.find(
            (analytic) =>
                analytic.Type === AnalyticsType.QuartileChart && analytic.Quartile != null
        );
    }, [analytics]);

    if (loading) {
        return (
            <div className="flex w-full h-full justify-center items-center">
                <Loading />
            </div>
        );
    }

    if (quartileAnalytics == null || quartileAnalytics.Quartile == null) {
        return (
            <div className="flex w-full h-full justify-center items-center">
                <div className={classNames(ClassNames.Text, "text-sm")}>
                    Quartile data missing
                </div>
            </div>
        );
    }

    return <QuartileChart
                label={brand.Name}
                hide={index > 0 ? ["y"] : undefined}
                borderless={true}
                allowExport={true}
                min={quartileAnalytics.Quartile.Min}
                max={quartileAnalytics.Quartile.Max}
                median={quartileAnalytics.Quartile.Median}
                average={quartileAnalytics.Quartile.Average}
                lowerQuartile={quartileAnalytics.Quartile.FirstQuartile}
                upperQuartile={quartileAnalytics.Quartile.ThirdQuartile}
                yMin={yMin}
                yMax={yMax}
            />
};

const QuartileComparisons: FC<{ brands: Brand[]; conditions: Conditions }> = ({
    brands,
    conditions,
}) => {
    const [brandMap, setBrandMap] = useState<{ [brandId: string]: { count: number, range: [number, number]} }>({});

    const { data: brandsBarData, yMin, yMax } = useMemo(() => {
        return {
            data: brands.map((brand) => {
                const value = brandMap[brand.Id];
                if (value == null) {
                    return { label: brand.Name, value: 0 }
                }
                return { label: brand.Name, value: value.count };
            }),
            yMin: min(values(brandMap).map(val => val.range[0])) ?? 0,
            yMax: max(values(brandMap).map(val => val.range[1])) ?? 1000,
        }
    }, [brandMap, brands]);

    const updateBrandMap = (brandId: string, count: number, range: [number, number]) => {
        setBrandMap((prev) => ({
            ...prev,
            [brandId]: { count, range },
        }));
    };

    return (
        <div className="flex flex-col w-full gap-4">
            <div className={classNames(ClassNames.Title, "text-lg")}>Distribution Analysis</div>
            <div className="flex w-full gap-4">
                <div className="flex flex-[1]">
                    <HorizontalBarChart data={brandsBarData} />
                </div>
                <div className="flex flex-[2]">
                    {brands.map((brand, index) => (
                        <QuartileBrandSection index={index} key={brand.Id} brand={brand} conditions={conditions} updateBrandMap={updateBrandMap}
                            yMin={yMin} yMax={yMax} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const HeatMapComparisons: FC<{ brands: Brand[], conditions: Conditions }> = ({ brands, conditions }) => {
    const sector = useAppSelector(state => state.global.sector);
    const [currentBrandIds, setCurrentBrandIds] = useState<string[]>(brands.length > 0 ? [brands[0].Id] : []);
    const { data: attributes } = useGetProductAttributesQuery({
        variables: {
            sector,
        }
    });
    const [xAxis, setXAxis] = useState<string[]>(["attributes8"]);
    const [yAxis, setYAxis] = useState<string[]>(["attributes4"]);
    const { data } = useGetPriceAnalyzerChartsQuery({
        variables: {
            brandId: currentBrandIds[0],
            conditions,
            input: {
                HeatMapInput: [xAxis[0], yAxis[0]],
            },
        },
    });
    const { openStatus, open, close, filterConditions } = useEmbedProductList();

    const attributesDropdown = useMemo(() => {
        if (attributes == null) {
            return [];
        }
        const items = attributes.ProductAttributes.map(attribute => getDropdownItem(attribute.Key, attribute.Name));
        items.unshift(getDropdownItem("sub_category", "Sub Category"));
        return items;
    }, [attributes]);

    const brandDropdownItems = useMemo(() => {
        return brands.map(brand => getDropdownItem(brand.Id, toTitleCase(brand.Name)));
    }, [brands]);

    const heatMapData = useMemo(() => {
        const heatMapChart = data?.PriceAnalyzerCharts.find(chart => chart.Type === AnalyticsType.HeatMapChart)?.HeatMapChart;
        if (heatMapChart == null) {
            return [];
        }
        const { Values, X, Y } = heatMapChart;
        return Values.map((value, index) => {
            const xIndex = Math.floor(index / Y.length) % X.length;
            const yIndex = index % Y.length;
            return {
                x: Y[yIndex],
                y: X[xIndex],
                value,
            };
        });
    }, [data]);

    const radarData = useMemo(() => {
        const heatMapChart = data?.PriceAnalyzerCharts.find(chart => chart.Type === AnalyticsType.HeatMapChart)?.HeatMapChart;
        if (heatMapChart == null) {
            return [];
        }
        const { Values, X, Y } = heatMapChart;
        return X.map(x => {
            const data: { label: string, value: number }[] = [];
            let maxValue = 0;
            for (const [index, value] of Values.entries()) {
                const xIndex = index % X.length;
                const yIndex = Math.floor(index / X.length);
                const xLabel = X[xIndex];
                const yLabel = Y[yIndex];
                if (xLabel === x) {
                    if (value > maxValue) {
                        maxValue = value;
                    }
                    data.push({
                        label: toTitleCase(yLabel),
                        value,
                    });
                }
            }
            return {
                name: toTitleCase(x),
                data,
                maxValue,
            }
        });
    }, [data?.PriceAnalyzerCharts]);

    const priceBracketsData = useMemo(() => {
        const scattedChart = data?.PriceAnalyzerCharts.find(chart => chart.Type === AnalyticsType.ScatteredChart)?.ScatteredChart;
        if (scattedChart == null) {
            return [];
        }
        return scattedChart.Labels.map((label, i) => ({
            x: scattedChart.Values[i],
            y: i+1,
            label,
        }));
    }, [data?.PriceAnalyzerCharts]);

    const handleHeatMapClick = useCallback((y: string, x: string) => {
        open({
            ...conditions,
            ["A"+xAxis[0].slice(1, )]: [x],
            ["A"+yAxis[0].slice(1, )]: [y],
        });
    }, [conditions, open, xAxis, yAxis]);

    return <div className="flex flex-col gap-4 w-full">
        <EmbedProductList open={openStatus} conditions={filterConditions} close={close} />
        <div className={classNames("flex w-full justify-between items-center", ClassNames.BottomLine)}>
            <div className={classNames(ClassNames.Title, "text-base w-full")}>HeatMap Analysis</div>
            <div className="flex gap-4 w-full justify-end items-center">
                <Dropdown items={brandDropdownItems} placeholder="Brand" selectedId={currentBrandIds} onChange={setCurrentBrandIds} />
                <Dropdown items={attributesDropdown} placeholder="X Axis" selectedId={xAxis} onChange={setXAxis} />
                {cloneElement(Icons.RightArrow, {
                    className: classNames(ClassNames.Text, "w-4 h-4"),
                })}
                <Dropdown items={attributesDropdown} placeholder="Y Axis" selectedId={yAxis} onChange={setYAxis} />
            </div>
        </div>
        <div className="flex flex-col gap-2 grow">
            <HeatMap data={heatMapData} onClick={handleHeatMapClick} />
        </div>
        <div className={classNames(ClassNames.Title, "text-base w-full", ClassNames.BottomLine)}>Radar Analysis</div>
        <div className="flex h-[50vh] overflow-x-auto ">
            {radarData.map(data => <div className="h-full min-w-[450px]">
                <DomainRadarChart name={data.name} maxValue={data.maxValue} data={data.data} />
            </div>)}
        </div>
        <div className={classNames(ClassNames.Title, "text-base w-full", ClassNames.BottomLine)}>Price Brackets Analysis</div>
        <div className="flex flex-col gap-2 grow h-[25vh]">
            <ScatterPlotWithBuckets data={priceBracketsData} sort={["x"]} tooltip={(point) => <>
                {point.label && <strong>{point.label}</strong>}
                <div>Price: {formatToIndianNumbering(point.x)}</div>
            </>} />
        </div>
    </div>
}

export const PriceAnalyzer: FC = () => {
    const sector = useAppSelector(state => state.global.sector);
    const comparingBrands = useAppSelector(state => state.global.comparingBrands.filter(brand => brand.Sectors.filter(s => s === sector).length > 0));
    const filterProps = useFilter();

    const handleQuery = useCallback(() => {
        filterProps.setConditions(getFilterConditions(filterProps));
    }, [filterProps]);

    return <InternalPage routes={[InternalRoutes.Brands.PriceAnalyzer]}>
        <div className={classNames("flex justify-between w-full mb-4", ClassNames.BottomLine)}>
            <div className={classNames(ClassNames.Title, "text-xl")}>Pricing Analyzer</div>
            <Filter {...filterProps} onClick={handleQuery} />
        </div>
        <div className="flex flex-col gap-16 w-[calc(100vw-64px)] mb-[10vh]">
            <QuartileComparisons brands={comparingBrands} conditions={filterProps.conditions} />
            <HeatMapComparisons brands={comparingBrands} conditions={filterProps.conditions} />
        </div>
    </InternalPage>
}