import { cloneElement, FC, useMemo } from 'react';
import classNames from 'classnames';
import { max, min } from 'lodash';
import { ClassNames } from '../classes';
import { formatToIndianCurrency, toTitleCase } from '../../utils/functions';
import { Icons } from '../icons';

export const HeatMap: FC<{ data: { x: string; y: string; value: number }[], onClick?: (x: string, y: string) => void }> = ({ data, onClick }) => {
  const { scaledData, xKeys, yKeys } = useMemo(() => {
    if (data.length === 0) return { scaledData: [], xKeys: [], yKeys: [] };

    const values = data.map((d) => d.value);
    const minValue = min(values) ?? 0;
    const maxValue = max(values) ?? 0;
    const scale = maxValue - minValue || 1;

    const xKeys = Array.from(new Set(data.map((d) => d.x)));
    const yKeys = Array.from(new Set(data.map((d) => d.y)));

    const scaledData = data.map((item) => ({
      ...item,
      scaledValue: ((item.value - minValue) / scale) * 100,
    }));

    return { scaledData, xKeys, yKeys };
  }, [data]);

  return (
    <div className="h-full flex gap-4 grow">
      <div className="flex flex-col sticky left-0 top-0 z-10 pt-[138px]">
        {yKeys.map((y) => (
          <div key={y} className={classNames(ClassNames.Text, "text-sm h-[40px]")}>
            {toTitleCase(y)}
          </div>
        ))}
      </div>

      <div className="flex flex-col grow gap-2 max-w-[100vw] overflow-x-auto pt-16">
        <div className="flex w-full sticky top-0 z-10">
          {xKeys.map((x) => (
            <div key={x} className={classNames(ClassNames.Text, "text-sm -rotate-[60deg] -translate-y-full min-w-[100px] mb-8")}>
              {toTitleCase(x)}
            </div>
          ))}
        </div>
        <div className="flex">
          <div className="flex flex-col">
            {yKeys.map((y, yi) => (
              <div key={y} className={classNames("flex h-[40px] bg-white dark:bg-white", {
                "rounded-t-xl": yi === 0,
                "rounded-b-xl": yi === yKeys.length-1,
              })}>
                {xKeys.map((x, xi) => {
                  const cellData = scaledData.find((item) => item.x === x && item.y === y);
                  const value = cellData?.scaledValue ?? 0;
                  return (
                    <div
                      key={x}
                      onClick={() => onClick?.(x, y)}
                      className={classNames(
                        "min-w-[100px] h-[40px] flex items-center justify-center border border-black/5 dark:border-white/5 text-neutral-300 group/cell relative overflow-hidden",
                        {
                          "rounded-tl-xl": xi === 0 && yi === 0,
                          "rounded-tr-xl": xi === xKeys.length - 1 && yi === 0,
                          "rounded-bl-xl": xi === 0 && yi === yKeys.length - 1,
                          "rounded-br-xl": xi === xKeys.length - 1 && yi === yKeys.length - 1,
                          "bg-green-950/50 dark:bg-green-950/50": value < 30,
                          "bg-green-950/60 dark:bg-green-950/60": value >= 30 && value < 50,
                          "bg-green-950/70 dark:bg-green-950/70": value >= 50 && value < 70,
                          "bg-amber-950/60 dark:bg-amber-950/60": value >= 70 && value < 85,
                          "bg-amber-950/70 dark:bg-amber-950/70": value >= 85 && value < 90,
                          "bg-red-950/10 dark:bg-red-950/60": value >= 90 && value < 95,
                          "bg-red-950/20 dark:bg-red-950/70": value >= 95,
                        }
                      )}
                    >
                      <span className="text-xs">{formatToIndianCurrency(cellData?.value ?? 0)}</span>
                      <div className={classNames("hidden group-hover/cell:flex absolute inset-0 backdrop-blur-md backdrop-brightness-50 justify-center items-center cursor-pointer", {
                        "!hidden": onClick == null,
                      })}>{cloneElement(Icons.Search, {
                          className: "w-4 h-4",
                      })}</div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
