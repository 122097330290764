import { values } from "lodash";
import { ReactNode } from "react";
import { LogoutPage } from "../pages/auth/logout";
import { BrandAnalyticsPage } from "../pages/brand/brand-analytics";
import { BrandPage } from "../pages/brand/brands";
import { DashboardPage } from "../pages/dashboard/dashboard";
import { ProductAnalyticsPage } from "../pages/product/product-analytics";
import { ProductPage } from "../pages/product/products";
import { BrandLocations } from "../pages/brand/brand-locations";
import { AdminPage } from "../pages/admin/admin";
import { PriceAnalyzer } from "../pages/brand/price-analyzer";

export type IInternalRoute = {
    name: string;
    path: string;
    component: ReactNode;
    public?: boolean;
}

export const InternalRoutes = {
    Dashboard: {
        name: "Home",
        path: "/",
        component: <DashboardPage />,
    },
    Brands: {
        Brands: {
            name: "Brand",
            path: "/brand",
            component: <BrandPage />,
        },
        Analytics: {
            name: "Brand Analytics",
            path: "/brand/analytics",
            component: <BrandAnalyticsPage />,
        },
        PriceAnalyzer: {
            name: "Price Analyzer",
            path: "/brand/price-analyzer",
            component: <PriceAnalyzer />,
        },
        Locations: {
            name: "Brand Locations",
            path: "/brand/locations",
            component: <BrandLocations />,
        },
    },
    Product: {
        Product: {
            name: "Product",
            path: "/product",
            component: <ProductPage />,
        },
        ProductAnalytics: {
            name: "Products Analytics",
            path: "/compare",
            component: <ProductAnalyticsPage />,
        },
    },
    Admin: {
        name: "Admin",
        path: "/admin",
        component: <AdminPage />,
    },
    Logout: {
        name: "Logout",
        path: "/logout",
        component: <LogoutPage />,
    },
}

export const getRoutes = (): IInternalRoute[] => {
    const allRoutes: IInternalRoute[] = [];
    const currentRoutes = values(InternalRoutes);
    while (currentRoutes.length > 0) {
        const currentRoute = currentRoutes.shift();
        if (currentRoute == null) {
            continue;
        }
        if ("path" in currentRoute) {
            allRoutes.push(currentRoute);
            continue;
        }
        currentRoutes.push(...values((currentRoute)));
    }
    return allRoutes;
}