import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { isArray, max, min } from "lodash";
import { cloneElement, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { ClassNames } from "../../components/classes";
import { Table } from "../../components/table";
import { ViewControl, ViewControlType } from "../../components/view-control";
import { Conditions, Product, useGetProductsLazyQuery } from "../../generated/graphql";
import { ProductDetails } from "./product-details";
import { PAGE_SIZE, ProductCard } from "./products";
import { Icons } from "../../components/icons";

export const useEmbedProductList = () => {
    const [filterConditions, setFitlerConditions] = useState<Conditions>({});
    const [openStatus, setOpenStatus] = useState<boolean>(false);
    const open = useCallback((conditions: Conditions) => {
        setFitlerConditions(conditions);
        setOpenStatus(true);
    }, []);
    const close = useCallback(() => {
      setOpenStatus(false)
    }, []);
    return {
        filterConditions,
        openStatus,
        open,
        close,
    }
}

export const EmbedProductList: FC<{ conditions: Conditions, open: boolean, close: () => void,  }> = ({ conditions, open, close }) => {
  const [view, setView] = useState<ViewControlType>("image-sm");
  const [productToShow, setProductToShow] = useState<Product | undefined>();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [getProducts, { loading } ] = useGetProductsLazyQuery({
    onCompleted(data) {
      setProducts(p => [...p, ...data.Products as Product[] ?? []]);
    },
  });

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        getProducts({
          variables: {
            pageSize: PAGE_SIZE,
            pageOffset: products.length,
            conditions,
          },
        });
      }
    }
  }, [getProducts, products.length, conditions]);
  
  const handleQuery = useCallback(() => {
    getProducts({
      variables: {
        pageSize: PAGE_SIZE,
        pageOffset: 0,
        conditions,
      },
      onCompleted(data) {
        setProducts(data.Products as Product[]);
      },
      nextFetchPolicy: "network-only",
    });
  }, [conditions, getProducts]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    handleQuery();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions]);

  const rows = useMemo(() => {
    if (products.length === 0) {
      return [];
    }
    return products.map(product => {
      const skusPrice = product.SKUs.map(sku => sku.Price);
      const minPrice = min(skusPrice);
      const maxPrice = max(skusPrice);
      const colors = product.SKUs.reduce((all: Set<string>, one) => {
        (one.Images?.map(image => image.Color) ?? []).forEach(color => all.add(color));
        return all;
      }, new Set<string>());
      return [product.Name, maxPrice == null ? "Unknown" : minPrice === maxPrice ? `${maxPrice}` : `${minPrice} - ${maxPrice}`, product.Description, product.Labels, [...colors.values()]];
    });
  }, [products]);

  const handleClose = useCallback(() => {
    setProducts([]);
    close();
  }, [close]);

  return (
    <AnimatePresence mode="wait">
        <motion.div className={twMerge(classNames("fixed z-50 top-0 right-0 h-full w-[50vw] shadow-2xl shadow-black dark:bg-black/10 bg-white/50 backdrop-blur-2xl dark:backdrop-blur-lg p-8"))}
            variants={{
                open: { opacity: 1, right: 0, display: "flex" },
                close: { opacity: 0, right: -100, transitionEnd: {
                  display: "none",
                },
              },
            }}
            animate={open ? "open" : "close"} onClick={(e) => e.stopPropagation()}>
            <div className="flex w-full h-full gap-20">
                <div className="flex flex-col h-[90vh] w-full gap-4">
                    <div className="flex justify-between w-full">
                        <div className={classNames(ClassNames.Title, "text-2xl")}>Products</div>
                        <button className={ClassNames.Button} onClick={handleClose}>
                            {cloneElement(Icons.Cancel, {
                                className: "w-4 h-4",
                            })}
                            Close
                        </button>
                    </div>
                    <div className="w-full flex justify-end">
                        <ViewControl loading={loading} view={view} setView={setView} />
                    </div>
                    {
                        view === "table" &&
                        <div ref={tableRef} className="w-full h-[90vh] overflow-auto">
                            <Table className="w-full" columns={["Name", "Price", "Description", "Tags", "Colors"]} currentPage={0} rows={rows} totalPages={1}
                            onClickRow={index => setProductToShow(products[index])}
                            customElement={{
                                3: (cell) => (
                                <div className="px-2 flex gap-1">
                                    {isArray(cell.value) ? cell.value.map(label => (
                                    <div className="bg-teal-500/50 px-2 py-[2px] w-fit rounded-3xl text-[10px]">{label}</div>
                                    )) : null}
                                </div>
                                ),
                                4: (cell) => (
                                <div className="px-2 flex gap-1 flex-wrap overflow-y-auto">
                                    {isArray(cell.value) ? cell.value.map(label => (
                                    <div className="bg-teal-500/50 px-2 py-[2px] rounded-3xl text-[10px]">{label}</div>
                                    )) : null}
                                </div>
                                )
                            }} />
                        </div>
                    }
                    {
                        view !== "table" &&
                        <div ref={containerRef} className="flex flex-wrap w-full items-center gap-4 space-y-2 content-start h-[90vh] overflow-auto" >
                            {products.map((product) => (
                                <ProductCard className={classNames({
                                "w-[150px] h-[250px]": view === "image-sm",
                                "w-[22vw] h-[50vw]": view === "image-md",
                                "w-[45vw] h-[50vw]": view === "image-lg",
                                })} key={product.Id} product={product} onClick={() => setProductToShow(product)} />
                            ))}
                        </div>
                    }
                </div>
            </div>
            {productToShow != null && productToShow.Brand != null && (
                <ProductDetails
                    className="dark:bg-black/70"
                    brand={productToShow.Brand}
                    product={productToShow}
                    onCancel={() => setProductToShow(undefined)}
                />
            )}
        </motion.div>
    </AnimatePresence>
  );
};