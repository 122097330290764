import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Analytics = {
  __typename?: 'Analytics';
  HeatMapChart?: Maybe<HeatMapChartAnalytics>;
  Highlight?: Maybe<HighlightAnalytics>;
  PieChart?: Maybe<PieChartAnalytics>;
  Quartile?: Maybe<QuartileAnalytics>;
  ScatteredChart?: Maybe<ScatteredChartAnalytics>;
  SubTitle?: Maybe<Scalars['String']['output']>;
  Title: Scalars['String']['output'];
  Type: AnalyticsType;
};

export enum AnalyticsType {
  ColorChart = 'ColorChart',
  HeatMapChart = 'HeatMapChart',
  Highlight = 'Highlight',
  PieChart = 'PieChart',
  QuartileChart = 'QuartileChart',
  ScatteredChart = 'ScatteredChart',
  VerticalDistributionChart = 'VerticalDistributionChart'
}

export type Brand = {
  __typename?: 'Brand';
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Sectors: Array<BrandSector>;
  TradingMethods: Array<BrandTradingMethod>;
};

export type BrandLocation = {
  __typename?: 'BrandLocation';
  Address: Scalars['String']['output'];
  Brand?: Maybe<Brand>;
  Contact: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  Images: Array<BrandLocationImage>;
  Latitude: Scalars['Float']['output'];
  Longitude: Scalars['Float']['output'];
  Name: Scalars['String']['output'];
  OpeningTimes: Scalars['String']['output'];
  SourceId: Scalars['String']['output'];
  Url: Scalars['String']['output'];
};

export type BrandLocationImage = {
  __typename?: 'BrandLocationImage';
  DataUrl: Scalars['String']['output'];
  Url: Scalars['String']['output'];
};

export enum BrandSector {
  Fashion = 'fashion',
  Pharma = 'pharma'
}

export enum BrandTradingMethod {
  Domestic = 'domestic',
  Export = 'export',
  Import = 'import'
}

export type Conditions = {
  Attributes1?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes2?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes3?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes4?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes5?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes6?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes7?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes8?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes9?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes10?: InputMaybe<Array<Scalars['String']['input']>>;
  BrandIds?: InputMaybe<Array<Scalars['String']['input']>>;
  CategoryNames?: InputMaybe<Array<Scalars['String']['input']>>;
  MaxDiscount?: InputMaybe<Scalars['Int']['input']>;
  MaxPrice?: InputMaybe<Scalars['Int']['input']>;
  MinDiscount?: InputMaybe<Scalars['Int']['input']>;
  MinPrice?: InputMaybe<Scalars['Int']['input']>;
  SubCategory?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type HeatMapChartAnalytics = {
  __typename?: 'HeatMapChartAnalytics';
  Values: Array<Scalars['Float']['output']>;
  X: Array<Scalars['String']['output']>;
  Y: Array<Scalars['String']['output']>;
};

export type HighlightAnalytics = {
  __typename?: 'HighlightAnalytics';
  Value: Scalars['String']['output'];
};

export type InputProduct = {
  Attributes1?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes2?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes3?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes4?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes5?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes6?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes7?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes8?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes9?: InputMaybe<Array<Scalars['String']['input']>>;
  Attributes10?: InputMaybe<Array<Scalars['String']['input']>>;
  Labels?: InputMaybe<Array<Scalars['String']['input']>>;
  Sanitized: Scalars['Boolean']['input'];
  SubCategory?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LoginCredentials = {
  Token: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  CreateReport?: Maybe<Scalars['Boolean']['output']>;
  UpdateValue: Scalars['Boolean']['output'];
};


export type MutationUpdateValueArgs = {
  id: Scalars['String']['input'];
  product: InputProduct;
};

export type PieChartAnalytics = {
  __typename?: 'PieChartAnalytics';
  Labels: Array<Scalars['String']['output']>;
  Values: Array<Scalars['Float']['output']>;
};

export type PriceAnalyzerChartsInput = {
  HeatMapInput: Array<Scalars['String']['input']>;
};

export type Product = {
  __typename?: 'Product';
  Attributes1?: Maybe<Array<Scalars['String']['output']>>;
  Attributes2?: Maybe<Array<Scalars['String']['output']>>;
  Attributes3?: Maybe<Array<Scalars['String']['output']>>;
  Attributes4?: Maybe<Array<Scalars['String']['output']>>;
  Attributes5?: Maybe<Array<Scalars['String']['output']>>;
  Attributes6?: Maybe<Array<Scalars['String']['output']>>;
  Attributes7?: Maybe<Array<Scalars['String']['output']>>;
  Attributes8?: Maybe<Array<Scalars['String']['output']>>;
  Attributes9?: Maybe<Array<Scalars['String']['output']>>;
  Attributes10?: Maybe<Array<Scalars['String']['output']>>;
  Brand?: Maybe<Brand>;
  Composition: Scalars['String']['output'];
  Description: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  Labels: Array<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
  SKUs: Array<ProductSku>;
  Sanitized: Scalars['Boolean']['output'];
  SourceId: Scalars['String']['output'];
  SubCategory?: Maybe<Array<Scalars['String']['output']>>;
  Url: Scalars['String']['output'];
};

export type ProductAttribute = {
  __typename?: 'ProductAttribute';
  FixedValues: Array<Scalars['String']['output']>;
  Key: Scalars['String']['output'];
  Name: Scalars['String']['output'];
};

export type ProductSku = {
  __typename?: 'ProductSKU';
  Discount: Scalars['Float']['output'];
  DiscountPercent: Scalars['Float']['output'];
  Id: Scalars['String']['output'];
  Images?: Maybe<Array<ProductSkuImage>>;
  OldPrice: Scalars['Float']['output'];
  Price: Scalars['Float']['output'];
  Sizes?: Maybe<Array<ProductSkuSize>>;
  SourceId: Scalars['String']['output'];
};

export type ProductSkuImage = {
  __typename?: 'ProductSKUImage';
  Color: Scalars['String']['output'];
  Url: Scalars['String']['output'];
};

export type ProductSkuSize = {
  __typename?: 'ProductSKUSize';
  ComingSoon: Scalars['Boolean']['output'];
  Size: Scalars['String']['output'];
};

export type QuartileAnalytics = {
  __typename?: 'QuartileAnalytics';
  Average: Scalars['Float']['output'];
  FirstQuartile: Scalars['Float']['output'];
  Max: Scalars['Float']['output'];
  Median: Scalars['Float']['output'];
  Min: Scalars['Float']['output'];
  ThirdQuartile: Scalars['Float']['output'];
};

export type Query = {
  __typename?: 'Query';
  Brand: Array<Brand>;
  BrandAnalytics: Array<Analytics>;
  BrandLocations: Array<BrandLocation>;
  BrandProducts: Array<Product>;
  OverallAnalytics: Array<Analytics>;
  PriceAnalyzerCharts: Array<Analytics>;
  ProductAttributes: Array<ProductAttribute>;
  Products: Array<Product>;
  SearchSimilarProducts: Array<Product>;
  Version: Scalars['String']['output'];
};


export type QueryBrandArgs = {
  prefix?: InputMaybe<Scalars['String']['input']>;
  sector: BrandSector;
};


export type QueryBrandAnalyticsArgs = {
  brandId: Scalars['String']['input'];
  conditions: Conditions;
};


export type QueryBrandLocationsArgs = {
  brandIds: Array<Scalars['String']['input']>;
  sector: BrandSector;
};


export type QueryBrandProductsArgs = {
  brandId: Scalars['String']['input'];
  conditions: Conditions;
  pageOffset: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryOverallAnalyticsArgs = {
  sector: BrandSector;
};


export type QueryPriceAnalyzerChartsArgs = {
  brandId: Scalars['String']['input'];
  conditions: Conditions;
  input: PriceAnalyzerChartsInput;
};


export type QueryProductAttributesArgs = {
  sector: Scalars['String']['input'];
};


export type QueryProductsArgs = {
  conditions: Conditions;
  pageOffset: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QuerySearchSimilarProductsArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type ScatteredChartAnalytics = {
  __typename?: 'ScatteredChartAnalytics';
  Labels: Array<Scalars['String']['output']>;
  Values: Array<Scalars['Float']['output']>;
};

export type StatusResponse = {
  __typename?: 'StatusResponse';
  Status: Scalars['Boolean']['output'];
};

export type GetBrandAnalyticsQueryVariables = Exact<{
  brandId: Scalars['String']['input'];
  conditions: Conditions;
}>;


export type GetBrandAnalyticsQuery = { __typename?: 'Query', BrandAnalytics: Array<{ __typename?: 'Analytics', Type: AnalyticsType, Title: string, SubTitle?: string | null, Highlight?: { __typename?: 'HighlightAnalytics', Value: string } | null, PieChart?: { __typename?: 'PieChartAnalytics', Values: Array<number>, Labels: Array<string> } | null, Quartile?: { __typename?: 'QuartileAnalytics', Min: number, Max: number, Average: number, Median: number, FirstQuartile: number, ThirdQuartile: number } | null }> };

export type GetBrandLocationsQueryVariables = Exact<{
  sector: BrandSector;
  brandIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type GetBrandLocationsQuery = { __typename?: 'Query', BrandLocations: Array<{ __typename?: 'BrandLocation', Id: string, SourceId: string, Name: string, Address: string, Url: string, Latitude: number, Longitude: number, OpeningTimes: string, Contact: string, Images: Array<{ __typename?: 'BrandLocationImage', Url: string }>, Brand?: { __typename?: 'Brand', Id: string, Name: string } | null }> };

export type GetProductAttributesQueryVariables = Exact<{
  sector: Scalars['String']['input'];
}>;


export type GetProductAttributesQuery = { __typename?: 'Query', ProductAttributes: Array<{ __typename?: 'ProductAttribute', Name: string, Key: string, FixedValues: Array<string> }> };

export type GetBrandProductsQueryVariables = Exact<{
  brandId: Scalars['String']['input'];
  pageSize: Scalars['Int']['input'];
  pageOffset: Scalars['Int']['input'];
  conditions: Conditions;
}>;


export type GetBrandProductsQuery = { __typename?: 'Query', BrandProducts: Array<{ __typename?: 'Product', Id: string, Name: string, SourceId: string, Description: string, Composition: string, Url: string, Sanitized: boolean, Labels: Array<string>, SubCategory?: Array<string> | null, Attributes1?: Array<string> | null, Attributes2?: Array<string> | null, Attributes3?: Array<string> | null, Attributes4?: Array<string> | null, Attributes5?: Array<string> | null, Attributes6?: Array<string> | null, Attributes7?: Array<string> | null, Attributes8?: Array<string> | null, Attributes9?: Array<string> | null, Attributes10?: Array<string> | null, SKUs: Array<{ __typename?: 'ProductSKU', Id: string, SourceId: string, Price: number, OldPrice: number, Discount: number, Sizes?: Array<{ __typename?: 'ProductSKUSize', Size: string, ComingSoon: boolean }> | null, Images?: Array<{ __typename?: 'ProductSKUImage', Url: string, Color: string }> | null }> }> };

export type GetBrandsQueryVariables = Exact<{
  sector: BrandSector;
  prefix?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetBrandsQuery = { __typename?: 'Query', Brand: Array<{ __typename?: 'Brand', Id: string, Name: string, Sectors: Array<BrandSector>, TradingMethods: Array<BrandTradingMethod> }> };

export type GetPriceAnalyzerChartsQueryVariables = Exact<{
  brandId: Scalars['String']['input'];
  conditions: Conditions;
  input: PriceAnalyzerChartsInput;
}>;


export type GetPriceAnalyzerChartsQuery = { __typename?: 'Query', PriceAnalyzerCharts: Array<{ __typename?: 'Analytics', Title: string, SubTitle?: string | null, Type: AnalyticsType, ScatteredChart?: { __typename?: 'ScatteredChartAnalytics', Labels: Array<string>, Values: Array<number> } | null, HeatMapChart?: { __typename?: 'HeatMapChartAnalytics', X: Array<string>, Y: Array<string>, Values: Array<number> } | null }> };

export type GetOverallAnalyticsQueryVariables = Exact<{
  sector: BrandSector;
}>;


export type GetOverallAnalyticsQuery = { __typename?: 'Query', OverallAnalytics: Array<{ __typename?: 'Analytics', Type: AnalyticsType, Title: string, SubTitle?: string | null, Highlight?: { __typename?: 'HighlightAnalytics', Value: string } | null, PieChart?: { __typename?: 'PieChartAnalytics', Values: Array<number>, Labels: Array<string> } | null, Quartile?: { __typename?: 'QuartileAnalytics', Min: number, Max: number, Average: number, Median: number, FirstQuartile: number, ThirdQuartile: number } | null }> };

export type GetVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVersionQuery = { __typename?: 'Query', Version: string };

export type GetProductsQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageOffset: Scalars['Int']['input'];
  conditions: Conditions;
}>;


export type GetProductsQuery = { __typename?: 'Query', Products: Array<{ __typename?: 'Product', Id: string, Name: string, SourceId: string, Description: string, Composition: string, Labels: Array<string>, Url: string, SKUs: Array<{ __typename?: 'ProductSKU', Id: string, SourceId: string, Price: number, OldPrice: number, Discount: number, Sizes?: Array<{ __typename?: 'ProductSKUSize', Size: string, ComingSoon: boolean }> | null, Images?: Array<{ __typename?: 'ProductSKUImage', Url: string, Color: string }> | null }>, Brand?: { __typename?: 'Brand', Id: string, Name: string } | null }> };

export type SearchSimilarProductsQueryVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type SearchSimilarProductsQuery = { __typename?: 'Query', SearchSimilarProducts: Array<{ __typename?: 'Product', Id: string, Name: string, SourceId: string, Description: string, Composition: string, Labels: Array<string>, Url: string, SKUs: Array<{ __typename?: 'ProductSKU', Id: string, SourceId: string, Price: number, OldPrice: number, Discount: number, Sizes?: Array<{ __typename?: 'ProductSKUSize', Size: string, ComingSoon: boolean }> | null, Images?: Array<{ __typename?: 'ProductSKUImage', Url: string, Color: string }> | null }>, Brand?: { __typename?: 'Brand', Id: string, Name: string } | null }> };

export type UpdateValueMutationVariables = Exact<{
  id: Scalars['String']['input'];
  product: InputProduct;
}>;


export type UpdateValueMutation = { __typename?: 'Mutation', UpdateValue: boolean };


export const GetBrandAnalyticsDocument = gql`
    query GetBrandAnalytics($brandId: String!, $conditions: Conditions!) {
  BrandAnalytics(brandId: $brandId, conditions: $conditions) {
    Type
    Highlight {
      Value
    }
    PieChart {
      Values
      Labels
    }
    Quartile {
      Min
      Max
      Average
      Median
      FirstQuartile
      ThirdQuartile
    }
    Title
    SubTitle
  }
}
    `;

/**
 * __useGetBrandAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetBrandAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandAnalyticsQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      conditions: // value for 'conditions'
 *   },
 * });
 */
export function useGetBrandAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables> & ({ variables: GetBrandAnalyticsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables>(GetBrandAnalyticsDocument, options);
      }
export function useGetBrandAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables>(GetBrandAnalyticsDocument, options);
        }
export function useGetBrandAnalyticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables>(GetBrandAnalyticsDocument, options);
        }
export type GetBrandAnalyticsQueryHookResult = ReturnType<typeof useGetBrandAnalyticsQuery>;
export type GetBrandAnalyticsLazyQueryHookResult = ReturnType<typeof useGetBrandAnalyticsLazyQuery>;
export type GetBrandAnalyticsSuspenseQueryHookResult = ReturnType<typeof useGetBrandAnalyticsSuspenseQuery>;
export type GetBrandAnalyticsQueryResult = Apollo.QueryResult<GetBrandAnalyticsQuery, GetBrandAnalyticsQueryVariables>;
export const GetBrandLocationsDocument = gql`
    query GetBrandLocations($sector: BrandSector!, $brandIds: [String!]!) {
  BrandLocations(sector: $sector, brandIds: $brandIds) {
    Id
    SourceId
    Name
    Address
    Url
    Latitude
    Longitude
    Images {
      Url
    }
    OpeningTimes
    Contact
    Brand {
      Id
      Name
    }
  }
}
    `;

/**
 * __useGetBrandLocationsQuery__
 *
 * To run a query within a React component, call `useGetBrandLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandLocationsQuery({
 *   variables: {
 *      sector: // value for 'sector'
 *      brandIds: // value for 'brandIds'
 *   },
 * });
 */
export function useGetBrandLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetBrandLocationsQuery, GetBrandLocationsQueryVariables> & ({ variables: GetBrandLocationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandLocationsQuery, GetBrandLocationsQueryVariables>(GetBrandLocationsDocument, options);
      }
export function useGetBrandLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandLocationsQuery, GetBrandLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandLocationsQuery, GetBrandLocationsQueryVariables>(GetBrandLocationsDocument, options);
        }
export function useGetBrandLocationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBrandLocationsQuery, GetBrandLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandLocationsQuery, GetBrandLocationsQueryVariables>(GetBrandLocationsDocument, options);
        }
export type GetBrandLocationsQueryHookResult = ReturnType<typeof useGetBrandLocationsQuery>;
export type GetBrandLocationsLazyQueryHookResult = ReturnType<typeof useGetBrandLocationsLazyQuery>;
export type GetBrandLocationsSuspenseQueryHookResult = ReturnType<typeof useGetBrandLocationsSuspenseQuery>;
export type GetBrandLocationsQueryResult = Apollo.QueryResult<GetBrandLocationsQuery, GetBrandLocationsQueryVariables>;
export const GetProductAttributesDocument = gql`
    query GetProductAttributes($sector: String!) {
  ProductAttributes(sector: $sector) {
    Name
    Key
    FixedValues
  }
}
    `;

/**
 * __useGetProductAttributesQuery__
 *
 * To run a query within a React component, call `useGetProductAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductAttributesQuery({
 *   variables: {
 *      sector: // value for 'sector'
 *   },
 * });
 */
export function useGetProductAttributesQuery(baseOptions: Apollo.QueryHookOptions<GetProductAttributesQuery, GetProductAttributesQueryVariables> & ({ variables: GetProductAttributesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductAttributesQuery, GetProductAttributesQueryVariables>(GetProductAttributesDocument, options);
      }
export function useGetProductAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductAttributesQuery, GetProductAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductAttributesQuery, GetProductAttributesQueryVariables>(GetProductAttributesDocument, options);
        }
export function useGetProductAttributesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProductAttributesQuery, GetProductAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductAttributesQuery, GetProductAttributesQueryVariables>(GetProductAttributesDocument, options);
        }
export type GetProductAttributesQueryHookResult = ReturnType<typeof useGetProductAttributesQuery>;
export type GetProductAttributesLazyQueryHookResult = ReturnType<typeof useGetProductAttributesLazyQuery>;
export type GetProductAttributesSuspenseQueryHookResult = ReturnType<typeof useGetProductAttributesSuspenseQuery>;
export type GetProductAttributesQueryResult = Apollo.QueryResult<GetProductAttributesQuery, GetProductAttributesQueryVariables>;
export const GetBrandProductsDocument = gql`
    query GetBrandProducts($brandId: String!, $pageSize: Int!, $pageOffset: Int!, $conditions: Conditions!) {
  BrandProducts(
    brandId: $brandId
    pageSize: $pageSize
    pageOffset: $pageOffset
    conditions: $conditions
  ) {
    Id
    Name
    SourceId
    Description
    Composition
    Url
    Sanitized
    Labels
    SubCategory
    Attributes1
    Attributes2
    Attributes3
    Attributes4
    Attributes5
    Attributes6
    Attributes7
    Attributes8
    Attributes9
    Attributes10
    SKUs {
      Id
      SourceId
      Price
      OldPrice
      Discount
      Sizes {
        Size
        ComingSoon
      }
      Images {
        Url
        Color
      }
    }
  }
}
    `;

/**
 * __useGetBrandProductsQuery__
 *
 * To run a query within a React component, call `useGetBrandProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandProductsQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      pageSize: // value for 'pageSize'
 *      pageOffset: // value for 'pageOffset'
 *      conditions: // value for 'conditions'
 *   },
 * });
 */
export function useGetBrandProductsQuery(baseOptions: Apollo.QueryHookOptions<GetBrandProductsQuery, GetBrandProductsQueryVariables> & ({ variables: GetBrandProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandProductsQuery, GetBrandProductsQueryVariables>(GetBrandProductsDocument, options);
      }
export function useGetBrandProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandProductsQuery, GetBrandProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandProductsQuery, GetBrandProductsQueryVariables>(GetBrandProductsDocument, options);
        }
export function useGetBrandProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBrandProductsQuery, GetBrandProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandProductsQuery, GetBrandProductsQueryVariables>(GetBrandProductsDocument, options);
        }
export type GetBrandProductsQueryHookResult = ReturnType<typeof useGetBrandProductsQuery>;
export type GetBrandProductsLazyQueryHookResult = ReturnType<typeof useGetBrandProductsLazyQuery>;
export type GetBrandProductsSuspenseQueryHookResult = ReturnType<typeof useGetBrandProductsSuspenseQuery>;
export type GetBrandProductsQueryResult = Apollo.QueryResult<GetBrandProductsQuery, GetBrandProductsQueryVariables>;
export const GetBrandsDocument = gql`
    query GetBrands($sector: BrandSector!, $prefix: String) {
  Brand(sector: $sector, prefix: $prefix) {
    Id
    Name
    Sectors
    TradingMethods
  }
}
    `;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *      sector: // value for 'sector'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetBrandsQuery(baseOptions: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables> & ({ variables: GetBrandsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
      }
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export function useGetBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsSuspenseQueryHookResult = ReturnType<typeof useGetBrandsSuspenseQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<GetBrandsQuery, GetBrandsQueryVariables>;
export const GetPriceAnalyzerChartsDocument = gql`
    query GetPriceAnalyzerCharts($brandId: String!, $conditions: Conditions!, $input: PriceAnalyzerChartsInput!) {
  PriceAnalyzerCharts(brandId: $brandId, conditions: $conditions, input: $input) {
    Title
    SubTitle
    Type
    ScatteredChart {
      Labels
      Values
    }
    HeatMapChart {
      X
      Y
      Values
    }
  }
}
    `;

/**
 * __useGetPriceAnalyzerChartsQuery__
 *
 * To run a query within a React component, call `useGetPriceAnalyzerChartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriceAnalyzerChartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriceAnalyzerChartsQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      conditions: // value for 'conditions'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPriceAnalyzerChartsQuery(baseOptions: Apollo.QueryHookOptions<GetPriceAnalyzerChartsQuery, GetPriceAnalyzerChartsQueryVariables> & ({ variables: GetPriceAnalyzerChartsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPriceAnalyzerChartsQuery, GetPriceAnalyzerChartsQueryVariables>(GetPriceAnalyzerChartsDocument, options);
      }
export function useGetPriceAnalyzerChartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPriceAnalyzerChartsQuery, GetPriceAnalyzerChartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPriceAnalyzerChartsQuery, GetPriceAnalyzerChartsQueryVariables>(GetPriceAnalyzerChartsDocument, options);
        }
export function useGetPriceAnalyzerChartsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPriceAnalyzerChartsQuery, GetPriceAnalyzerChartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPriceAnalyzerChartsQuery, GetPriceAnalyzerChartsQueryVariables>(GetPriceAnalyzerChartsDocument, options);
        }
export type GetPriceAnalyzerChartsQueryHookResult = ReturnType<typeof useGetPriceAnalyzerChartsQuery>;
export type GetPriceAnalyzerChartsLazyQueryHookResult = ReturnType<typeof useGetPriceAnalyzerChartsLazyQuery>;
export type GetPriceAnalyzerChartsSuspenseQueryHookResult = ReturnType<typeof useGetPriceAnalyzerChartsSuspenseQuery>;
export type GetPriceAnalyzerChartsQueryResult = Apollo.QueryResult<GetPriceAnalyzerChartsQuery, GetPriceAnalyzerChartsQueryVariables>;
export const GetOverallAnalyticsDocument = gql`
    query GetOverallAnalytics($sector: BrandSector!) {
  OverallAnalytics(sector: $sector) {
    Type
    Highlight {
      Value
    }
    PieChart {
      Values
      Labels
    }
    Quartile {
      Min
      Max
      Average
      Median
      FirstQuartile
      ThirdQuartile
    }
    Title
    SubTitle
  }
}
    `;

/**
 * __useGetOverallAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetOverallAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverallAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverallAnalyticsQuery({
 *   variables: {
 *      sector: // value for 'sector'
 *   },
 * });
 */
export function useGetOverallAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables> & ({ variables: GetOverallAnalyticsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables>(GetOverallAnalyticsDocument, options);
      }
export function useGetOverallAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables>(GetOverallAnalyticsDocument, options);
        }
export function useGetOverallAnalyticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables>(GetOverallAnalyticsDocument, options);
        }
export type GetOverallAnalyticsQueryHookResult = ReturnType<typeof useGetOverallAnalyticsQuery>;
export type GetOverallAnalyticsLazyQueryHookResult = ReturnType<typeof useGetOverallAnalyticsLazyQuery>;
export type GetOverallAnalyticsSuspenseQueryHookResult = ReturnType<typeof useGetOverallAnalyticsSuspenseQuery>;
export type GetOverallAnalyticsQueryResult = Apollo.QueryResult<GetOverallAnalyticsQuery, GetOverallAnalyticsQueryVariables>;
export const GetVersionDocument = gql`
    query GetVersion {
  Version
}
    `;

/**
 * __useGetVersionQuery__
 *
 * To run a query within a React component, call `useGetVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVersionQuery(baseOptions?: Apollo.QueryHookOptions<GetVersionQuery, GetVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVersionQuery, GetVersionQueryVariables>(GetVersionDocument, options);
      }
export function useGetVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVersionQuery, GetVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVersionQuery, GetVersionQueryVariables>(GetVersionDocument, options);
        }
export function useGetVersionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetVersionQuery, GetVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVersionQuery, GetVersionQueryVariables>(GetVersionDocument, options);
        }
export type GetVersionQueryHookResult = ReturnType<typeof useGetVersionQuery>;
export type GetVersionLazyQueryHookResult = ReturnType<typeof useGetVersionLazyQuery>;
export type GetVersionSuspenseQueryHookResult = ReturnType<typeof useGetVersionSuspenseQuery>;
export type GetVersionQueryResult = Apollo.QueryResult<GetVersionQuery, GetVersionQueryVariables>;
export const GetProductsDocument = gql`
    query GetProducts($pageSize: Int!, $pageOffset: Int!, $conditions: Conditions!) {
  Products(pageSize: $pageSize, pageOffset: $pageOffset, conditions: $conditions) {
    Id
    Name
    SourceId
    Description
    Composition
    Labels
    Url
    SKUs {
      Id
      SourceId
      Price
      OldPrice
      Discount
      Sizes {
        Size
        ComingSoon
      }
      Images {
        Url
        Color
      }
    }
    Brand {
      Id
      Name
    }
  }
}
    `;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageOffset: // value for 'pageOffset'
 *      conditions: // value for 'conditions'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables> & ({ variables: GetProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export function useGetProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsSuspenseQueryHookResult = ReturnType<typeof useGetProductsSuspenseQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const SearchSimilarProductsDocument = gql`
    query SearchSimilarProducts($ids: [String!]!) {
  SearchSimilarProducts(ids: $ids) {
    Id
    Name
    SourceId
    Description
    Composition
    Labels
    Url
    SKUs {
      Id
      SourceId
      Price
      OldPrice
      Discount
      Sizes {
        Size
        ComingSoon
      }
      Images {
        Url
        Color
      }
    }
    Brand {
      Id
      Name
    }
  }
}
    `;

/**
 * __useSearchSimilarProductsQuery__
 *
 * To run a query within a React component, call `useSearchSimilarProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSimilarProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSimilarProductsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSearchSimilarProductsQuery(baseOptions: Apollo.QueryHookOptions<SearchSimilarProductsQuery, SearchSimilarProductsQueryVariables> & ({ variables: SearchSimilarProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchSimilarProductsQuery, SearchSimilarProductsQueryVariables>(SearchSimilarProductsDocument, options);
      }
export function useSearchSimilarProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSimilarProductsQuery, SearchSimilarProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchSimilarProductsQuery, SearchSimilarProductsQueryVariables>(SearchSimilarProductsDocument, options);
        }
export function useSearchSimilarProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchSimilarProductsQuery, SearchSimilarProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchSimilarProductsQuery, SearchSimilarProductsQueryVariables>(SearchSimilarProductsDocument, options);
        }
export type SearchSimilarProductsQueryHookResult = ReturnType<typeof useSearchSimilarProductsQuery>;
export type SearchSimilarProductsLazyQueryHookResult = ReturnType<typeof useSearchSimilarProductsLazyQuery>;
export type SearchSimilarProductsSuspenseQueryHookResult = ReturnType<typeof useSearchSimilarProductsSuspenseQuery>;
export type SearchSimilarProductsQueryResult = Apollo.QueryResult<SearchSimilarProductsQuery, SearchSimilarProductsQueryVariables>;
export const UpdateValueDocument = gql`
    mutation UpdateValue($id: String!, $product: InputProduct!) {
  UpdateValue(id: $id, product: $product)
}
    `;
export type UpdateValueMutationFn = Apollo.MutationFunction<UpdateValueMutation, UpdateValueMutationVariables>;

/**
 * __useUpdateValueMutation__
 *
 * To run a mutation, you first call `useUpdateValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateValueMutation, { data, loading, error }] = useUpdateValueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useUpdateValueMutation(baseOptions?: Apollo.MutationHookOptions<UpdateValueMutation, UpdateValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateValueMutation, UpdateValueMutationVariables>(UpdateValueDocument, options);
      }
export type UpdateValueMutationHookResult = ReturnType<typeof useUpdateValueMutation>;
export type UpdateValueMutationResult = Apollo.MutationResult<UpdateValueMutation>;
export type UpdateValueMutationOptions = Apollo.BaseMutationOptions<UpdateValueMutation, UpdateValueMutationVariables>;