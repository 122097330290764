import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type IUser = {
  name: string;
  roles: string[];
}

type IJwtToken = {
  accessToken: string;
  refreshToken: string;
}

type IAuthStatus = "pending" | "success" | "failed" | "logging-out" | "unauthenticated";

export type IAuthState = {
  status: IAuthStatus;
  token?: IJwtToken;
  user?: IUser;
}

const initialState: IAuthState = {
  status: "pending",
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<IJwtToken | undefined>) => {
      state.token = action.payload;
    },
    setStatus: (state, action: PayloadAction<IAuthStatus>) => {
      state.status = action.payload;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    }
  },
})

export const AuthActions = authSlice.actions;
export const authReducers = authSlice.reducer;