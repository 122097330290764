import classNames from 'classnames';
import { FC } from 'react';
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from 'recharts';
import { ClassNames } from '../classes';

export const DomainRadarChart: FC<{ name: string, data: { label: string, value: number }[], maxValue: number }> = ({
  name,
  data,
  maxValue,
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart cx="50%" cy="50%" outerRadius="60%" data={data}>
        <PolarGrid stroke='rgba(255,255,255,0.1)' />
        <PolarAngleAxis dataKey="label" fontSize={8} />
        <PolarRadiusAxis fontSize={8} domain={[0, maxValue]} />
        <Radar name={name} dataKey="value" fill="rgb(21, 94, 117)" stroke="rgba(255,255,255,0.25)" fillOpacity={0.6} />
        <Legend>
          <div className={classNames(ClassNames.Text, "opacity-100 text-sm")}>{name}</div>
        </Legend>
        <Tooltip content={({ active, payload }) => {
          if (active && payload && payload.length) {
            const { label, value } = payload[0].payload;
            return (
              <div className={ClassNames.ChartTooltip}>
                {label && <strong>{label}</strong>}
                <div>{value}</div>
              </div>
            );
          }
          return null;
        }} />
      </RadarChart>
    </ResponsiveContainer>
  );
};
