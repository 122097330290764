import classNames from "classnames";
import { createContext, FC, useCallback, useContext, useMemo, useRef } from "react";
import { Handle, Position, ReactFlowProvider, useEdgesState, useNodesState } from "reactflow";
import { twMerge } from "tailwind-merge";
import { ClassNames } from "../../components/classes";
import { Graph, IGraphCardProps, IGraphInstance } from "../../components/graph/graph";
import { createEdge, createNode } from "../../components/graph/utils";
import { Icons } from "../../components/icons";
import { Loading } from "../../components/loading";
import { InternalPage } from "../../components/page";
import { InternalRoutes } from "../../config/routes";
import { Product, useSearchSimilarProductsLazyQuery } from "../../generated/graphql";
import { useAppSelector } from "../../store/hooks";
import { getFirstImageUrl, getImageUrl } from "../../utils/functions";


const SearchContext = createContext({
  search: (_id: string) => {},
  loading: false,
});

enum GraphElements {
  Product="Product",
}

export const ProductCard: FC<IGraphCardProps<Product & { search: () => void }>> = ({ data }) => {
  const { search } = useContext(SearchContext);

  if (data == null) {
      return (<div className={ClassNames.Card}>
          <Loading />
      </div>)
  }

  return (
      <>
          <Handle className="dark:border-white/5" type="target" position={Position.Left} />
          <div className={classNames(ClassNames.Card, "h-fit backdrop-blur-[2px] bg-transparent w-[350px] overflow-hidden")}>
            { getFirstImageUrl(data.SKUs) != null && <img className="w-[150px] h-full object-cover rounded-2xl" src={getImageUrl(getFirstImageUrl(data.SKUs)!)} alt="Product" /> }
            <div className="flex flex-col gap-2 p-4">
              <div className={classNames(ClassNames.Text, "text-lg font-bold")}>
                {data.Name}
              </div>
              <div className={classNames(ClassNames.Text, "text-sm")}>
                Category: {data.Labels.join(",")}
              </div>
              <div className={classNames(ClassNames.Text, "text-sm")}>
                Sub Categories: {data.SubCategory?.join(",")}
              </div>
              <div className={twMerge(classNames(ClassNames.Text, "text-sm self-start text-neutral-300"))}>₹ {data.SKUs?.[0]?.Price?.toLocaleString()}</div>
              <div className={classNames("absolute right-2 bottom-2 p-2", ClassNames.Button)} onClick={() => search(data.Id)}>
                {Icons.Search}
              </div>
            </div>
          </div>
          <Handle className="dark:border-white/5" type="source" position={Position.Right} />
      </>
  );
}

export const ProductAnalyticsPage: FC = () => {
  const comparingProducts = useAppSelector(state => state.global.comparingProducts);
  const [nodes, setNodes, onNodesChange] = useNodesState(comparingProducts.map(product => createNode({
    id: product.product.Id,
    type: GraphElements.Product,
    data: product.product,
  })));
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [searchSimilarProducts, { loading }] = useSearchSimilarProductsLazyQuery();
  const reactFlowRef = useRef<IGraphInstance>();

  const search = useCallback((id: string) => {
    searchSimilarProducts({
      variables: {
        ids: [id],
      },
      onCompleted(data) {
        const newNodes = data.SearchSimilarProducts.map(product => createNode({
          id: product.Id,
          type: GraphElements.Product,
          data: product,
        }));
        const edges = data.SearchSimilarProducts.map(product => createEdge(id, product.Id));
        setNodes(nds => [...nds, ...newNodes]);
        setEdges(edgs => [...edgs, ...edges]);

        setTimeout(() => {
          reactFlowRef.current?.layout("dagre");
        }, 300);
      },
    });
  }, [searchSimilarProducts, setEdges, setNodes]);

  const nodeTypes = useMemo(() => ({
    [GraphElements.Product]: ProductCard,
  }), []);

  const handleOnReady = useCallback((instance: IGraphInstance) => {
    reactFlowRef.current = instance;
    setTimeout(() => {
      reactFlowRef.current?.layout("dagre");
    }, 300);
  }, []);

  return <InternalPage routes={[InternalRoutes.Dashboard, {
    ...InternalRoutes.Product.ProductAnalytics,
  }]}>
    <div className={classNames("flex justify-between w-full mb-4", ClassNames.BottomLine)}>
      <div className={classNames(ClassNames.Title, "text-xl")}>Products Analytics</div>
    </div>
    <SearchContext.Provider value={{ search, loading }}>
      <ReactFlowProvider>
          {
              !loading && nodes.length === 0
              ? <div className="h-full w-full flex justify-center items-center">
                <div className={classNames(ClassNames.Text, "text-lg")}>
                  No products found
                </div>
              </div>
              : <Graph nodes={nodes} edges={edges} nodeTypes={nodeTypes}
                  setNodes={setNodes} setEdges={setEdges}
                  onNodesChange={onNodesChange} onEdgesChange={onEdgesChange}    
                  minZoom={0.1}
                  onReady={handleOnReady} />
          }
      </ReactFlowProvider>
    </SearchContext.Provider>
  </InternalPage>
}