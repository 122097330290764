import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { FC, ReactNode, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { IInternalRoute, InternalRoutes } from "../config/routes";
import { BrandSector } from "../generated/graphql";
import { GlobalActions } from "../store/global";
import { useAppSelector } from "../store/hooks";
import { Breadcrumb } from "./breadcrumbs";
import { ClassNames } from "./classes";
import { Dropdown, getDropdownItems } from "./dropdown";
import { Icons } from "./icons";
import { Logo } from "./logo";

const NavigationButton: FC<{
    currentPathName: string,
    pathName: string,
    children?: ReactNode,
}> = ({ currentPathName, pathName, children }) => {
    const navigate = useNavigate();
    return (
        <button className={twMerge(classNames(ClassNames.TitledButton, "relative text-sm", {
            [ClassNames.ActiveText]: currentPathName === pathName,
        }))} onClick={() => navigate(pathName)}>
            {children}
        </button>
    )
}

const NotificationPill: FC<{ className?: string, children?: ReactNode }> = ({ className, children }) => {
    return <motion.div key="compare-brands" className={twMerge("absolute top-0 -right-4 w-5 h-5 bg-green-700 dark:bg-green-500/20 text-neutral-300 rounded-full text-xs flex items-center justify-center", className)} initial={{ y: 10, scale: 0.5, opacity: 0 }}
    animate={{ y: -10, scale: 1, opacity: 1 }}
    exit={{ y: 10, scale: 0.5, opacity: 0 }}>
        {children}
    </motion.div>
}

export const TopBar: FC<{
    routes?: IInternalRoute[],
}> = ({ routes }) => {
    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const comparingBrands = useAppSelector(state => state.global.comparingBrands);
    const darkModeEnabled = useAppSelector(state => state.global.theme === "dark");
    const availableSectors = useAppSelector(state => getDropdownItems(state.auth.user?.roles?.filter(role => role.startsWith("sector-")).map(role => role.split("sector-")[1]) ?? []));
    const sector = useAppSelector(state => state.global.sector);
    const roles = useAppSelector(state => state.auth.user?.roles ?? []);
    const comparingProducts = useAppSelector(state => state.global.comparingProducts);

    const handleSectorChange = useCallback((newSector: string[]) => {
        dispatch(GlobalActions.setSector(newSector[0] as BrandSector));
    }, [dispatch]);

    useEffect(() => {
        if (sector == null && availableSectors.length > 1) {
            handleSectorChange([availableSectors[0].id]);
        }
    }, [availableSectors, handleSectorChange, sector]);

    return (<div className="flex justify-between items-center px-8">
        <div className="sticky z-10 top-2 left-4 w-fit rounded-xl py-2 transition-all flex gap-12 items-center">
            <div className="flex gap-1 items-center cursor-pointer"  onClick={() => navigate(InternalRoutes.Dashboard.path)}>
                <div className="w-8"><Logo /></div> 
                <div className={classNames(ClassNames.Title, "text-lg")}>
                    Nano Knot
                </div>
            </div>
            {availableSectors.length > 0 && <Dropdown searchable={true} className="text-sm" placeholder="Sector" items={availableSectors} selectedId={sector} onChange={handleSectorChange} />}
            <Breadcrumb routes={routes ?? []} active={routes?.at(-1)} />
        </div>
        <div className="flex gap-8 items-center">
            <div className="flex items-start gap-2">
                {roles.includes("super-admin") && <div className={classNames(ClassNames.OutlinedButton, "items-center hover:scale-110")} onClick={() => navigate(InternalRoutes.Admin.path)}>
                    {Icons.Config}
                </div>}
                <div className={classNames(ClassNames.OutlinedButton, "items-center hover:scale-110")} onClick={() => dispatch(GlobalActions.setTheme(darkModeEnabled ? "light" : "dark"))}>
                    {darkModeEnabled ? Icons.Moon : Icons.Sun}
                </div>
            </div>
            <NavigationButton pathName={InternalRoutes.Brands.Analytics.path} currentPathName={pathname}>
                {Icons.Analytics}
                Brand Analytics
                <AnimatePresence mode="wait">
                    {
                        comparingBrands.length > 0 &&
                        <NotificationPill>
                            {comparingBrands.length > 99 ? "99+" : comparingBrands.length}
                        </NotificationPill>
                    }
                </AnimatePresence>
            </NavigationButton>
            <NavigationButton pathName={InternalRoutes.Brands.Locations.path} currentPathName={pathname}>
                {Icons.Location}
                Brand Locations
            </NavigationButton>
            <NavigationButton pathName={InternalRoutes.Brands.PriceAnalyzer.path} currentPathName={pathname}>
                {Icons.Rupee}
                Price Analyzer
            </NavigationButton>
            <NavigationButton pathName={InternalRoutes.Product.ProductAnalytics.path} currentPathName={pathname}>
                {Icons.Compare}
                Product Analysis
                <AnimatePresence mode="wait">
                    {
                        comparingProducts.length > 0 &&
                        <NotificationPill>
                            {comparingProducts.length > 99 ? "99+" : comparingProducts.length}
                        </NotificationPill>
                    }
                </AnimatePresence>
            </NavigationButton>
            <button className={classNames(ClassNames.TitledButton, "text-sm")} onClick={() => navigate(InternalRoutes.Logout.path)}>
                {Icons.Logout}
                Logout
            </button>
        </div>
    </div>);
}