import classNames from "classnames";
import { cloneDeep, toNumber } from "lodash";
import { cloneElement, FC, useCallback, useEffect, useMemo, useState } from "react";
import { ClassNames } from "../../components/classes";
import { Dropdown, getDropdownItems, IDropdownItem } from "../../components/dropdown";
import { Icons } from "../../components/icons";
import { Input, ToggleInput } from "../../components/input";
import { InternalPage } from "../../components/page";
import { Tooltip } from "../../components/tooltip";
import { InternalRoutes } from "../../config/routes";
import { GetBrandProductsQuery, useGetBrandProductsLazyQuery, useGetBrandsQuery, useGetProductAttributesQuery, useUpdateValueMutation } from "../../generated/graphql";
import { notify } from "../../store/function";
import { useAppSelector } from "../../store/hooks";
import { getImageUrl, toTitleCase } from "../../utils/functions";
import { useLocation, useNavigate } from "react-router-dom";
import { categoryDropdownItems, subCategoryDropdownItems } from "../../components/filter";

const Tag: FC<{ label: string, onRemove: () => void }> = ({ label, onRemove }) => {
    return <div className={classNames(ClassNames.Text, "w-full text-sm bg-black/20 dark:bg-white/20 rounded-2xl group/pill px-4 items-center flex justify-center gap-2 max-w-[100px]")}>
        {label}
        <div className="opacity-0 group-hover/pill:opacity-100 transition-all" onClick={onRemove}>
            {cloneElement(Icons.Delete, {
                className: "w-4 h-4",
            })}
        </div>
    </div>
}

const Tags: FC<{ id: string, labels: string[], onAdd: (label: string) => void, onRemove: (label: string) => void, collapsable?: boolean, items: IDropdownItem[], onAddAll?: () => void, onRemoveAll?: () => void }> = ({ id, labels, onAdd, onRemove, collapsable, items, onAddAll, onRemoveAll }) => {
    return <div className={classNames("flex flex-col items-center max-w-[350px] flex-wrap gap-2", {
        "min-w-[150px]": !collapsable,
    })}>
        {labels.map(label => (
            <Tag key={`${id}-${label}`} label={label} onRemove={() => onRemove(label)} />
        ))}
        {
            items.length > 0 &&
            <>
                <Dropdown items={items.map(item => ({
                    ...item,
                    onClick: () => onAdd(item.id),
                }))} onlyHover={true} searchable={true}>
                    <div className={classNames(ClassNames.Text, "flex justify-center")}>{Icons.Add}</div>
                </Dropdown>
                { onAddAll && <div className={classNames(ClassNames.Button, ClassNames.Text, "text-xs")} onClick={onAddAll}>
                    Add All
                </div>}
                { onRemoveAll && <div className={classNames(ClassNames.Button, ClassNames.Text, "text-xs")} onClick={onRemoveAll}>
                    Remove All
                </div> }
            </>
        }
    </div>
}

export const AdminPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const getInitialStateFromUrl = useCallback((key: string, defaultValue: any) => {
    const params = new URLSearchParams(location.search);
    return params.get(key) ? JSON.parse(params.get(key)!) : defaultValue;
  }, [location.search]);

    const [prefix, setPrefix] = useState<string>("");
    const [brandIds, setBrandIds] = useState<string[]>(getInitialStateFromUrl("brandIds", [])); 
    const [categoryNames, setCategoryNames] = useState<string[]>(getInitialStateFromUrl("categoryNames", [])); 
    const [pageSize, setPageSize] = useState<string>(getInitialStateFromUrl("pageSize", "100"));
    const [pageOffset, setPageOffset] = useState<string>(getInitialStateFromUrl("pageOffset", "0"));
    const sector = useAppSelector(state => state.global.sector);
      const { data: brands } = useGetBrandsQuery({
        variables: {
          sector,
          prefix,
        }
    });
    const [getBrandProducts, { data }] = useGetBrandProductsLazyQuery();
    const [updateValueMutation, ] = useUpdateValueMutation();
    const [productsData, setProductsData] = useState<GetBrandProductsQuery["BrandProducts"]>([]);
    const { data: attributes } = useGetProductAttributesQuery({
        variables: {
            sector,
        }
    });

    const updateParams = useCallback((brandIds: string[], categoryNames: string[], pageSize: string, pageOffset: string) => {
        const params = new URLSearchParams();
        params.set("brandIds",  JSON.stringify(brandIds));
        params.set("categoryNames",  JSON.stringify(categoryNames));
        params.set("pageSize", JSON.stringify(pageSize));
        params.set("pageOffset", JSON.stringify(pageOffset));
        navigate({ search: params.toString() });
    }, [navigate]);

    const handleFilter = useCallback((brandIds: string[], categoryNames: string[]) => {
        updateParams(brandIds, categoryNames, pageSize, pageOffset);

        setBrandIds(brandIds);
        setCategoryNames(categoryNames);
        getBrandProducts({
            variables: {
                brandId: brandIds[0],
                conditions: {
                    CategoryNames: categoryNames,
                },
                pageOffset: toNumber(pageOffset),
                pageSize: toNumber(pageSize),
            },
            onCompleted(data) {
                setProductsData(data.BrandProducts);
            },
        });
      }, [getBrandProducts, pageOffset, pageSize, updateParams]);

    useEffect(() => {
        if (brandIds.length > 0) {
            handleFilter(brandIds, categoryNames);    
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const brandItems = useMemo(() => brands?.Brand.map(brand => ({ id: brand.Id, label: toTitleCase(brand.Name) })) ?? [], [brands?.Brand]);

    const handleUpdate = useCallback((id: string, value: any, attributeKey: string) => {
        const productIndex = productsData.findIndex(product => product.Id === id);
        if (productIndex === -1) {
            return notify("Unable to find the product", "warning");
        }
        const productFound = cloneDeep(productsData[productIndex]);
        (productFound as any)[attributeKey] = value;
        updateValueMutation({
            variables: {
                id,
                product: {
                    Labels: productFound.Labels,
                    SubCategory: productFound.SubCategory,
                    Sanitized: productFound.Sanitized,
                    Attributes1: productFound.Attributes1,
                    Attributes2: productFound.Attributes2,
                    Attributes3: productFound.Attributes3,
                    Attributes4: productFound.Attributes4,
                    Attributes5: productFound.Attributes5,
                    Attributes6: productFound.Attributes6,
                    Attributes7: productFound.Attributes7,
                    Attributes8: productFound.Attributes8,
                    Attributes9: productFound.Attributes9,
                    Attributes10: productFound.Attributes10,
                },
            },
            onCompleted(data) {
                if (data.UpdateValue) {
                    const newProductsData = cloneDeep(productsData);
                    newProductsData[productIndex] = productFound;
                    setProductsData(newProductsData);
                    return notify(`Updated ${attributeKey} for ${productFound.Name}`, "success");
                }
                return notify(`Unable to updated ${attributeKey} for ${productFound.Name}`, "error");
            },
        });
    }, [productsData, updateValueMutation]);

    const handleAdd = useCallback((id: string, value: string, attributeKey: string) => {
        const productIndex = productsData.findIndex(product => product.Id === id);
        if (productIndex === -1) {
            return notify("Unable to find the product", "warning");
        }
        const product = cloneDeep(productsData[productIndex]);
        (product as any)[attributeKey] = [...new Set([...((product as any)[attributeKey] ?? [] as string[]), value])];
        handleUpdate(id, (product as any)[attributeKey], attributeKey);
    }, [handleUpdate, productsData]);

    const handleRemove = useCallback((id: string, value: string, attributeKey: string) => {
        const productIndex = productsData.findIndex(product => product.Id === id);
        if (productIndex === -1) {
            return notify("Unable to find the product", "warning");
        }
        const product = cloneDeep(productsData[productIndex]);
        (product as any)[attributeKey] = ((product as any)[attributeKey] as string[]).filter(attribute => attribute !== value);
        handleUpdate(id, (product as any)[attributeKey], attributeKey);
    }, [handleUpdate, productsData]);

    const handleAddAll = useCallback((id: string, attributeKey: string) => {
        const productIndex = productsData.findIndex(product => product.Id === id);
        if (productIndex === -1) {
            return notify("Unable to find the product", "warning");
        }
        const product = cloneDeep(productsData[productIndex]);
        const attributeKeyLowerCase = attributeKey.toLowerCase();
        (product as any)[attributeKey] = attributes?.ProductAttributes.find(attribute => attribute.Key === attributeKeyLowerCase)!.FixedValues;
        handleUpdate(id, (product as any)[attributeKey], attributeKey);
    }, [attributes?.ProductAttributes, handleUpdate, productsData]);

    const handleRemoveAll = useCallback((id: string, attributeKey: string) => {
        const productIndex = productsData.findIndex(product => product.Id === id);
        if (productIndex === -1) {
            return notify("Unable to find the product", "warning");
        }
        const product = cloneDeep(productsData[productIndex]);
        (product as any)[attributeKey] = []
        handleUpdate(id, (product as any)[attributeKey], attributeKey);
    }, [handleUpdate, productsData]);

    const handleNext = useCallback(() => {
        const newPageOffset = (toNumber(pageOffset)+1).toString();
        setPageOffset(newPageOffset);
        updateParams(brandIds, categoryNames, pageSize, pageOffset);
    }, [brandIds, categoryNames, pageOffset, pageSize, updateParams]);

    const attributes1Items = useMemo(() => getDropdownItems(attributes?.ProductAttributes.at(0)?.FixedValues ?? []), [attributes?.ProductAttributes]);
    const attributes2Items = useMemo(() => getDropdownItems(attributes?.ProductAttributes.at(1)?.FixedValues ?? []), [attributes?.ProductAttributes]);
    const attributes3Items = useMemo(() => getDropdownItems(attributes?.ProductAttributes.at(2)?.FixedValues ?? []), [attributes?.ProductAttributes]);
    const attributes4Items = useMemo(() => getDropdownItems(attributes?.ProductAttributes.at(3)?.FixedValues ?? []), [attributes?.ProductAttributes]);
    const attributes5Items = useMemo(() => getDropdownItems(attributes?.ProductAttributes.at(4)?.FixedValues ?? []), [attributes?.ProductAttributes]);
    const attributes6Items = useMemo(() => getDropdownItems(attributes?.ProductAttributes.at(5)?.FixedValues ?? []), [attributes?.ProductAttributes]);
    const attributes7Items = useMemo(() => getDropdownItems(attributes?.ProductAttributes.at(6)?.FixedValues ?? []), [attributes?.ProductAttributes]);
    const attributes8Items = useMemo(() => getDropdownItems(attributes?.ProductAttributes.at(7)?.FixedValues ?? []), [attributes?.ProductAttributes]);
    const attributes9Items = useMemo(() => getDropdownItems(attributes?.ProductAttributes.at(8)?.FixedValues ?? []), [attributes?.ProductAttributes]);
    const attributes10Items = useMemo(() => getDropdownItems(attributes?.ProductAttributes.at(9)?.FixedValues ?? []), [attributes?.ProductAttributes]);

    return <InternalPage routes={[InternalRoutes.Admin]}>
        <div className="flex justify-between items-center w-[calc(100vw-48px)]">
            <div className={classNames(ClassNames.Text, "text-2xl")}>
                Tagging
            </div>
            <div className="flex gap-2 items-center">
                <Dropdown dropdownClassName="z-50" className="text-sm" placeholder="Brand" items={brandItems} selectedId={brandIds} onChange={(brandIds) => handleFilter(brandIds, categoryNames)}
                    searchable={true} inputProps={{
                        onKeyUp: e => setPrefix((e.target as HTMLInputElement).value),
                }} />
                <Dropdown dropdownClassName="z-50" className="text-sm" placeholder="Category Name" items={categoryDropdownItems} selectedId={categoryNames} onChange={(categoryNames) => handleFilter(brandIds, categoryNames)} />
                <div className="flex gap-2 items-center">
                    <div className={classNames(ClassNames.Text, "text-sm")}>Page offset</div>
                    <Input value={pageOffset} setValue={setPageOffset} inputProps={{ className: "w-[50px]"}} />
                </div>
                <div className="flex gap-2 items-center">
                    <div className={classNames(ClassNames.Text, "text-sm")}>Page size</div>
                    <Input value={pageSize} setValue={setPageSize} inputProps={{ className: "w-[50px]"}} />
                </div>
                <div className={classNames(ClassNames.Text, ClassNames.Button)} onClick={handleNext}>
                    Next
                    {Icons.RightChevron}
                </div>
            </div>
        </div>
        <div className="flex flex-col gap-2 h-full">
            <div className="sticky top-0 backdrop-blur-md bg-black/5 dark:bg-white/5 flex items-center gap-2 p-4 rounded-b-lg z-10">
                <div className={classNames(ClassNames.Text, "w-[110px]")}>Image</div>
                <div className={classNames(ClassNames.Text, "w-[150px]")}>Details</div>
                <div className={classNames(ClassNames.Text, "w-[100px]")}>Sanitized</div>
                <div className={classNames(ClassNames.Text, "w-[150px]")}>Labels</div>
                <div className={classNames(ClassNames.Text, "w-[150px]")}>SubCategory</div>
                {attributes?.ProductAttributes.map(attribute => (
                    <div className={classNames(ClassNames.Text, "w-[150px]")}>
                        {attribute.Name}
                    </div>
                ))}
            </div>
            {
                data?.BrandProducts == null || data.BrandProducts.length === 0
                ? <div className="flex w-full grow justify-center items-center">
                    <div className={classNames(ClassNames.Text, "text-lg")}>
                        No products found
                    </div>
                </div>
                : productsData.map((product, i) => (
                    <div key={`product-${product.Id}`} className={classNames("flex gap-2 p-4 rounded-xl border-b border-b-black/10 dark:border-b-white/10", {
                        "bg-green-200 dark:bg-green-800": product.Sanitized,
                    })}>
                        <img className="min-24 h-32 rounded-xl object-cover" src={getImageUrl(product.SKUs.at(0)?.Images?.at(0)?.Url ?? "")} alt="product" />
                        <div className="flex flex-col gap-1 max-w-[150px]">
                            <div className={classNames(ClassNames.Text, "text-sm")}>
                                {product.Name}
                            </div>
                            <Tooltip tooltip={<div className={classNames(ClassNames.Text, "text-xs")}>{product.Description}</div>}>
                                <div className={classNames(ClassNames.Text, "text-xs")}>
                                    {product.Description.slice(0, 250)}
                                </div>
                            </Tooltip>
                            <Tooltip tooltip={<div className={classNames(ClassNames.Text, "text-xs")}>{product.Composition}</div>}>
                                <div className={classNames(ClassNames.Text, "text-xs")}>
                                    {product.Composition.slice(0, 150)}
                                </div>
                            </Tooltip>
                        </div>
                        <div className="flex items-start">
                            <ToggleInput value={product.Sanitized} setValue={(value) => handleUpdate(product.Id, value, "Sanitized")} />
                        </div>
                        <Tags id={product.Id} items={categoryDropdownItems} labels={product.Labels ?? []} onAdd={(value) => handleAdd(product.Id, value, "Labels")} onRemove={(value) => handleRemove(product.Id, value, "Labels")} />
                        <Tags id={product.Id} items={subCategoryDropdownItems} labels={product.SubCategory ?? []} onAdd={(value) => handleAdd(product.Id, value, "SubCategory")} onRemove={(value) => handleRemove(product.Id, value, "SubCategory")} />
                        <Tags id={product.Id} items={attributes1Items} labels={product.Attributes1 ?? []} onAdd={(value) => handleAdd(product.Id, value, "Attributes1")} onRemove={(value) => handleRemove(product.Id, value, "Attributes1")} onAddAll={() => handleAddAll(product.Id, "Attributes1")} onRemoveAll={() => handleRemoveAll(product.Id, "Attributes1")} />
                        <Tags id={product.Id} items={attributes2Items} labels={product.Attributes2 ?? []} onAdd={(value) => handleAdd(product.Id, value, "Attributes2")} onRemove={(value) => handleRemove(product.Id, value, "Attributes2")} onAddAll={() => handleAddAll(product.Id, "Attributes2")} onRemoveAll={() => handleRemoveAll(product.Id, "Attributes2")} />
                        <Tags id={product.Id} items={attributes3Items} labels={product.Attributes3 ?? []} onAdd={(value) => handleAdd(product.Id, value, "Attributes3")} onRemove={(value) => handleRemove(product.Id, value, "Attributes3")} onAddAll={() => handleAddAll(product.Id, "Attributes3")} onRemoveAll={() => handleRemoveAll(product.Id, "Attributes3")} />
                        <Tags id={product.Id} items={attributes4Items} labels={product.Attributes4 ?? []} onAdd={(value) => handleAdd(product.Id, value, "Attributes4")} onRemove={(value) => handleRemove(product.Id, value, "Attributes4")} onAddAll={() => handleAddAll(product.Id, "Attributes4")} onRemoveAll={() => handleRemoveAll(product.Id, "Attributes4")} />
                        <Tags id={product.Id} items={attributes5Items} labels={product.Attributes5 ?? []} onAdd={(value) => handleAdd(product.Id, value, "Attributes5")} onRemove={(value) => handleRemove(product.Id, value, "Attributes5")} onAddAll={() => handleAddAll(product.Id, "Attributes5")} onRemoveAll={() => handleRemoveAll(product.Id, "Attributes5")} />
                        <Tags id={product.Id} items={attributes6Items} labels={product.Attributes6 ?? []} onAdd={(value) => handleAdd(product.Id, value, "Attributes6")} onRemove={(value) => handleRemove(product.Id, value, "Attributes6")} onAddAll={() => handleAddAll(product.Id, "Attributes6")} onRemoveAll={() => handleRemoveAll(product.Id, "Attributes6")} />
                        <Tags id={product.Id} items={attributes7Items} labels={product.Attributes7 ?? []} onAdd={(value) => handleAdd(product.Id, value, "Attributes7")} onRemove={(value) => handleRemove(product.Id, value, "Attributes7")} onAddAll={() => handleAddAll(product.Id, "Attributes7")} onRemoveAll={() => handleRemoveAll(product.Id, "Attributes7")} />
                        <Tags id={product.Id} items={attributes8Items} labels={product.Attributes8 ?? []} onAdd={(value) => handleAdd(product.Id, value, "Attributes8")} onRemove={(value) => handleRemove(product.Id, value, "Attributes8")} collapsable={true} onAddAll={() => handleAddAll(product.Id, "Attributes8")} onRemoveAll={() => handleRemoveAll(product.Id, "Attributes8")} />
                        <Tags id={product.Id} items={attributes9Items} labels={product.Attributes9 ?? []} onAdd={(value) => handleAdd(product.Id, value, "Attributes9")} onRemove={(value) => handleRemove(product.Id, value, "Attributes9")} collapsable={true} onAddAll={() => handleAddAll(product.Id, "Attributes9")} onRemoveAll={() => handleRemoveAll(product.Id, "Attributes9")} />
                        <Tags id={product.Id} items={attributes10Items} labels={product.Attributes10 ?? []} onAdd={(value) => handleAdd(product.Id, value, "Attributes10")} onRemove={(value) => handleRemove(product.Id, value, "Attributes10")} collapsable={true} onAddAll={() => handleAddAll(product.Id, "Attributes10")} onRemoveAll={() => handleRemoveAll(product.Id, "Attributes10")} />
                    </div>
                ))
            }
        </div>
    </InternalPage>
}