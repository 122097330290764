import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { isEqual } from "lodash";
import { cloneElement, FC, ReactElement, ReactNode, useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { Conditions, ProductAttribute, useGetBrandsQuery, useGetProductAttributesQuery } from "../generated/graphql";
import { useAppSelector } from "../store/hooks";
import { formatToIndianNumbering, toTitleCase } from "../utils/functions";
import { ClassNames } from "./classes";
import { Dropdown, getDropdownItems } from "./dropdown";
import { Icons } from "./icons";
import { Loading } from "./loading";
import { Slider } from "./slider";

export const categoryDropdownItems = getDropdownItems(["Men", "Women", "Home", "Jewellery", "Beauty"]);
export const subCategoryDropdownItems = getDropdownItems(["bags", "accessories", "trousers", "sports", "trench-coats", "sweaters", "dupattas", "trend", "kurtas", "blouses", "charms", "jeans", "sweats", "basics", "outerwear", "denim-washes", "dresses-overlays", "kaftans", "shorts", "bangles", "dresses", "joggers", "suits", "jackets", "shoes", "waistcoats", "maternity-wear", "bodysuits", "makeup", "earrings", "tops", "necklaces", "scarves", "perfumes", "cardigans", "jewellery", "rings", "bottoms", "innerwear", "sweatshirts", "knitwear", "fleece", "bracelets", "sarees", "blazers", "best-seller", "chains", "shirts", "co-ord-sets", "loungewear", "t-shirts", "skirts"]);

type IFilterProps = ReturnType<typeof useFilter>;
export function getFilterConditions(filterProps: IFilterProps) {
  return {
    BrandIds: filterProps.brandIds,
    MaxDiscount: filterProps.maxDiscount,
    MinDiscount: filterProps.minDiscount,
    MaxPrice: filterProps.maxPrice,
    MinPrice: filterProps.minPrice,
    CategoryNames: filterProps.categoryNames,
    SubCategory: filterProps.subCategory,
    Attributes1: filterProps.attributes1,
    Attributes2: filterProps.attributes2,
    Attributes3: filterProps.attributes3,
    Attributes4: filterProps.attributes4,
    Attributes5: filterProps.attributes5,
    Attributes6: filterProps.attributes6,
    Attributes7: filterProps.attributes7,
    Attributes8: filterProps.attributes8,
    Attributes9: filterProps.attributes9,
    Attributes10: filterProps.attributes10,
  };
}

export const useFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sector = useAppSelector(state => state.global.sector);
  const { data: brands, loading } = useGetBrandsQuery({
    variables: {
      sector,
    }
  });
  const { data: productAttributes } = useGetProductAttributesQuery({
    variables: {
      sector,
    }
  });

  const getInitialStateFromUrl = useCallback((key: string, defaultValue: any) => {
    const params = new URLSearchParams(location.search);
    return params.get(key) ? JSON.parse(params.get(key)!) : defaultValue;
  }, [location.search]);

  const [brandIds, setBrandIds] = useState<string[]>(getInitialStateFromUrl('brandIds', []));
  const [[minPrice, maxPrice], setPrice] = useState<[number, number]>(getInitialStateFromUrl('priceRange', [0, 100000]));
  const [[minDiscount, maxDiscount], setDiscount] = useState<[number, number]>(getInitialStateFromUrl('discountRange', [0, 20000]));
  const [categoryNames, setCategoryNames] = useState<string[]>(getInitialStateFromUrl('categoryNames', ["Men"]));
  const [subCategory, setSubCategory] = useState<string[]>(getInitialStateFromUrl('subCategory', []));
  const [attributes1, setAttributes1] = useState<string[]>(getInitialStateFromUrl('attributes1', []));
  const [attributes2, setAttributes2] = useState<string[]>(getInitialStateFromUrl('attributes2', []));
  const [attributes3, setAttributes3] = useState<string[]>(getInitialStateFromUrl('attributes3', []));
  const [attributes4, setAttributes4] = useState<string[]>(getInitialStateFromUrl('attributes4', []));
  const [attributes5, setAttributes5] = useState<string[]>(getInitialStateFromUrl('attributes5', []));
  const [attributes6, setAttributes6] = useState<string[]>(getInitialStateFromUrl('attributes6', []));
  const [attributes7, setAttributes7] = useState<string[]>(getInitialStateFromUrl('attributes7', []));
  const [attributes8, setAttributes8] = useState<string[]>(getInitialStateFromUrl('attributes8', []));
  const [attributes9, setAttributes9] = useState<string[]>(getInitialStateFromUrl('attributes9', []));
  const [attributes10, setAttributes10] = useState<string[]>(getInitialStateFromUrl('attributes10', []));

  const [conditions, setConditions] = useState<Conditions>({
    BrandIds: [],
    MinDiscount: minDiscount,
    MaxDiscount: maxDiscount,
    MinPrice: minPrice,
    MaxPrice: maxPrice,
    CategoryNames: [],
    SubCategory: [],
    Attributes1: [],
    Attributes2: [],
    Attributes3: [],
    Attributes4: [],
    Attributes5: [],
    Attributes6: [],
    Attributes7: [],
    Attributes8: [],
    Attributes9: [],
    Attributes10: [],
  });

  const brandItems = useMemo(() => brands?.Brand.map(brand => ({ id: brand.Id, label: toTitleCase(brand.Name) })) ?? [], [brands?.Brand]);
  const enableApply = useMemo(() => {
    return !isEqual(conditions, {
      BrandIds: brandIds,
      MaxDiscount: maxDiscount,
      MinDiscount: minDiscount,
      MaxPrice: maxPrice,
      MinPrice: minPrice,
      CategoryNames: categoryNames,
      SubCategory: subCategory,
      Attributes1: attributes1,
      Attributes2: attributes2,
      Attributes3: attributes3,
      Attributes4: attributes4,
      Attributes5: attributes5,
      Attributes6: attributes6,
      Attributes7: attributes7,
      Attributes8: attributes8,
      Attributes9: attributes9,
      Attributes10: attributes10,
    });
  }, [conditions, brandIds, maxDiscount, minDiscount, maxPrice, minPrice, categoryNames, subCategory, attributes1, attributes2, attributes3, attributes4, attributes5, attributes6, attributes7, attributes8, attributes9, attributes10]);

  const updateQueryParameters = useCallback(() => {
    const params = new URLSearchParams();

    if (brandIds.length) params.set('brandIds', JSON.stringify(brandIds));
    params.set('priceRange', JSON.stringify([minPrice, maxPrice]));
    params.set('discountRange', JSON.stringify([minDiscount, maxDiscount]));
    if (categoryNames.length) params.set('categoryNames', JSON.stringify(categoryNames));
    if (subCategory.length) params.set('subCategory', JSON.stringify(subCategory));
    if (attributes1.length) params.set('attributes1', JSON.stringify(attributes1));
    if (attributes2.length) params.set('attributes2', JSON.stringify(attributes2));
    if (attributes3.length) params.set('attributes3', JSON.stringify(attributes3));
    if (attributes4.length) params.set('attributes4', JSON.stringify(attributes4));
    if (attributes5.length) params.set('attributes5', JSON.stringify(attributes5));
    if (attributes6.length) params.set('attributes6', JSON.stringify(attributes6));
    if (attributes7.length) params.set('attributes7', JSON.stringify(attributes7));
    if (attributes8.length) params.set('attributes8', JSON.stringify(attributes8));
    if (attributes9.length) params.set('attributes9', JSON.stringify(attributes9));
    if (attributes10.length) params.set('attributes10', JSON.stringify(attributes10));

    navigate({ search: params.toString() });
  }, [attributes1, attributes10, attributes2, attributes3, attributes4, attributes5, attributes6, attributes7, attributes8, attributes9, brandIds, categoryNames, maxDiscount, maxPrice, minDiscount, minPrice, navigate, subCategory]);

  return {
        loading,
        brandIds, brandItems, minPrice, maxPrice, minDiscount, maxDiscount, categoryNames, subCategory,
        attributes1, attributes2, attributes3, attributes4, attributes5, attributes6, attributes7, attributes8, attributes9, attributes10, conditions,
        setBrandIds, setPrice, setDiscount, setCategoryNames, setSubCategory,
        setAttributes1, setAttributes2, setAttributes3, setAttributes4, setAttributes5, setAttributes6, setAttributes7, setAttributes8, setAttributes9, setAttributes10,
        setConditions: (newConditions: Conditions) => {
          updateQueryParameters();
          setConditions(newConditions);
        },
        enableApply,
        updateQueryParameters,
        productAttributes,
    }
}

const HoverableCollapse: FC<{ collapsed: ReactNode, children: ReactNode }> = ({ collapsed, children }) => {
  const [hover, setHover] = useState(false);
  return <AnimatePresence mode="wait">
    <motion.div className="transition-all border rounded-lg border-black/10 dark:border-white/10 px-3 py-1 dark:bg-white/5 dark:hover:bg-white/10"
      onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {
        hover
        ? <motion.div key="children" className="flex items-center gap-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ x: { type: "spring", stiffness: 100 }, duration: 0.8, delay: 0.2, }}>
          {children}
        </motion.div>
        : <motion.div key="collapsed" className="flex items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ x: { type: "spring", stiffness: 100 }, duration: 0.8, delay: 0.2, }}>
          {collapsed}
        </motion.div>
      }
    </motion.div>
  </AnimatePresence>
}

export const Filter: FC<ReturnType<typeof useFilter> & { ignore?: "brandIds"[], onClick?: () => void }> = ({
        loading,
        brandItems,
        brandIds, setBrandIds,
        categoryNames, setCategoryNames,
        subCategory, setSubCategory,
        minPrice, maxPrice, setPrice,
        minDiscount, maxDiscount, setDiscount,
        attributes1, setAttributes1,
        attributes2, setAttributes2,
        attributes3, setAttributes3,
        attributes4, setAttributes4,
        attributes5, setAttributes5,
        attributes6, setAttributes6,
        attributes7, setAttributes7,
        attributes8, setAttributes8,
        attributes9, setAttributes9,
        attributes10, setAttributes10,
        ignore = [],
        onClick,
        enableApply,
        productAttributes,
}) => {

  const productAttributesMap = useMemo(() => {
    const map: Record<string, ProductAttribute> = {};
    for (const productAttribute of productAttributes?.ProductAttributes ?? []) {
      map[productAttribute.Key] = productAttribute;
    }
    return map;
  }, [productAttributes?.ProductAttributes]);

  const attributesDropdowns = useMemo(() => {
    const dropdowns: ReactElement[] = [];
    if ("attributes1" in productAttributesMap) dropdowns.push(<Dropdown key={"attributes1-dropdown"} searchable={true} className="text-sm" placeholder={productAttributesMap["attributes1"].Name} items={getDropdownItems(productAttributesMap["attributes1"].FixedValues)} selectedId={attributes1} onChange={setAttributes1} multiple={true} />);
    if ("attributes2" in productAttributesMap) dropdowns.push(<Dropdown key={"attributes1-dropdown"} searchable={true} className="text-sm" placeholder={productAttributesMap["attributes2"].Name} items={getDropdownItems(productAttributesMap["attributes2"].FixedValues)} selectedId={attributes2} onChange={setAttributes2} multiple={true} />);
    if ("attributes3" in productAttributesMap) dropdowns.push(<Dropdown key={"attributes1-dropdown"} searchable={true} className="text-sm" placeholder={productAttributesMap["attributes3"].Name} items={getDropdownItems(productAttributesMap["attributes3"].FixedValues)} selectedId={attributes3} onChange={setAttributes3} multiple={true} />);
    if ("attributes4" in productAttributesMap) dropdowns.push(<Dropdown key={"attributes1-dropdown"} searchable={true} className="text-sm" placeholder={productAttributesMap["attributes4"].Name} items={getDropdownItems(productAttributesMap["attributes4"].FixedValues)} selectedId={attributes4} onChange={setAttributes4} multiple={true} />);
    if ("attributes5" in productAttributesMap) dropdowns.push(<Dropdown key={"attributes1-dropdown"} searchable={true} className="text-sm" placeholder={productAttributesMap["attributes5"].Name} items={getDropdownItems(productAttributesMap["attributes5"].FixedValues)} selectedId={attributes5} onChange={setAttributes5} multiple={true} />);
    if ("attributes6" in productAttributesMap) dropdowns.push(<Dropdown key={"attributes1-dropdown"} searchable={true} className="text-sm" placeholder={productAttributesMap["attributes6"].Name} items={getDropdownItems(productAttributesMap["attributes6"].FixedValues)} selectedId={attributes6} onChange={setAttributes6} multiple={true} />);
    if ("attributes7" in productAttributesMap) dropdowns.push(<Dropdown key={"attributes1-dropdown"} searchable={true} className="text-sm" placeholder={productAttributesMap["attributes7"].Name} items={getDropdownItems(productAttributesMap["attributes7"].FixedValues)} selectedId={attributes7} onChange={setAttributes7} multiple={true} />);
    if ("attributes8" in productAttributesMap) dropdowns.push(<Dropdown key={"attributes1-dropdown"} searchable={true} className="text-sm" placeholder={productAttributesMap["attributes8"].Name} items={getDropdownItems(productAttributesMap["attributes8"].FixedValues)} selectedId={attributes8} onChange={setAttributes8} multiple={true} />);
    if ("attributes9" in productAttributesMap) dropdowns.push(<Dropdown key={"attributes1-dropdown"} searchable={true} className="text-sm" placeholder={productAttributesMap["attributes9"].Name} items={getDropdownItems(productAttributesMap["attributes9"].FixedValues)} selectedId={attributes9} onChange={setAttributes9} multiple={true} />);
    if ("attributes10" in productAttributesMap) dropdowns.push(<Dropdown key={"attributes1-dropdown"} searchable={true} className="text-sm" placeholder={productAttributesMap["attributes10"].Name} items={getDropdownItems(productAttributesMap["attributes10"].FixedValues)} selectedId={attributes10} onChange={setAttributes10} multiple={true} />);
    return dropdowns;
  }, [attributes1, attributes10, attributes2, attributes3, attributes4, attributes5, attributes6, attributes7, attributes8, attributes9, productAttributesMap, setAttributes1, setAttributes10, setAttributes2, setAttributes3, setAttributes4, setAttributes5, setAttributes6, setAttributes7, setAttributes8, setAttributes9]);
 
  const attributesCount = useMemo(() => {
    return attributes1.length + attributes2.length + attributes3.length + attributes4.length + attributes5.length + attributes6.length + attributes7.length + attributes8.length + attributes9.length + attributes10.length;
  }, [attributes1.length, attributes10.length, attributes2.length, attributes3.length, attributes4.length, attributes5.length, attributes6.length, attributes7.length, attributes8.length, attributes9.length]);

  return <div className="flex gap-2 items-center">
    {
      !ignore.includes("brandIds") && <Dropdown searchable={true} className="text-sm border px-3 py-1 border-white/10 rounded-lg bg-white/5" placeholder="Brand" selectedId={brandIds} items={brandItems} onChange={setBrandIds} multiple={true} />
    }
    <HoverableCollapse collapsed={<div className={classNames(ClassNames.Button, "text-sm")}>Category ({categoryNames.length+subCategory.length})</div>}>
      <Dropdown searchable={true} className="text-sm" placeholder="Category" items={categoryDropdownItems} selectedId={categoryNames} onChange={setCategoryNames} multiple={true} />
      <Dropdown searchable={true} className="text-sm" placeholder="Sub-Category" items={subCategoryDropdownItems} selectedId={subCategory} onChange={setSubCategory} multiple={true} />
    </HoverableCollapse>
    <HoverableCollapse collapsed={<div className={classNames(ClassNames.Button, "text-sm")}>Pricing ({minPrice}-{formatToIndianNumbering(maxPrice)})</div>}>
      <Slider className="text-sm" placeholder="Price" min={minPrice} max={maxPrice} onChange={setPrice} step={1000} />
      <Slider className="text-sm" placeholder="Discount" min={minDiscount} max={maxDiscount} onChange={setDiscount} step={1000} />
    </HoverableCollapse>
    <HoverableCollapse collapsed={<div className={classNames(ClassNames.Button, "text-sm")}>Attributes ({attributesCount})</div>}>
      {attributesDropdowns}
    </HoverableCollapse>
    {
        onClick != null && loading
        ?  <div className="h-fit w-[50px]">
            <Loading className="h-8" />
        </div>
        : <div className={twMerge(classNames(ClassNames.Button, "border dark:border-green-500/10 rounded-lg px-3 py-1 dark:text-green-500 transition-all text-sm", {
            "opacity-100": enableApply,
            "opacity-25 pointer-events-none": !enableApply,
        }))} onClick={onClick}>
            Apply
            {cloneElement(Icons.CheckCircle, {
            className: "w-4 h-4",
            })}
        </div>
    }
  </div>
}