import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { isArray } from "lodash";
import { cloneElement, DetailedHTMLProps, FC, InputHTMLAttributes, ReactNode, useCallback, useMemo, useState } from "react";
import { toTitleCase } from "../utils/functions";
import { ClassNames } from "./classes";
import { Icons } from "./icons";
import { Input } from "./input";
import { twMerge } from "tailwind-merge";

export type IDropdownItem = { id: string, label: string, onClick?: () => void };

export function getDropdownItem(item: string, label?: string) {
    return { id: item, label: label ?? toTitleCase(item) };
}
export function getDropdownItems(items: string[]) {
    return items.map(item => getDropdownItem(item));
}

export const Dropdown: FC<{
    children?: ReactNode;
    placeholder?: string,
    selectedId?: string | string[],
    items: IDropdownItem[];
    onChange?: (itemIds: string[]) => void;
    multiple?: boolean,
    className?: string,
    dropdownClassName?: string,
    onlyHover?: boolean;
    searchable?: boolean;
    inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}> = ({ placeholder, items = [], onChange, selectedId = [], multiple = false, className, dropdownClassName, children, onlyHover, searchable = false, inputProps = {} }) => {
    const [selected, setSelected] = useState<string[]>(
        isArray(selectedId) ? selectedId : [selectedId]
    );
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");

    const handleOpen = useCallback(() => {
        setOpen(o => !o);
    }, []);

    const handleItemClick = useCallback((item: IDropdownItem) => {
        if (item.onClick != null) {
            item.onClick();
        } else {
            setSelected(prevSelected => {
                let newSelected;
                
                if (multiple) {
                    if (prevSelected.includes(item.id)) {
                        newSelected = prevSelected.filter(id => id !== item.id);
                    } else {
                        newSelected = [...prevSelected, item.id];
                    }
                } else {
                    newSelected = [item.id];
                    setOpen(false); 
                }
                
                onChange?.(newSelected); 
                return newSelected;
            });
        }
    }, [multiple, onChange]);

    const handleMouseEnter = useCallback(() => {
        setOpen(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setOpen(false);
    }, []);

    const selectedSet = useMemo(() => new Set(selected), [selected]);

    const filteredItems = useMemo(() => {
        const lowerCaseSearch = search.toLowerCase();
        return items.filter(item => item.label.toLowerCase().includes(lowerCaseSearch));
    }, [items, search]);

    const selectedItemLabel = useMemo(() => toTitleCase(items.find(item => item.id === [...selectedSet][0])?.label ?? ""), [items, selectedSet]);

    return (
        <div className="relative inline-block text-left"
            onMouseEnter={onlyHover ? handleMouseEnter : undefined}
            onMouseLeave={onlyHover ? handleMouseLeave : undefined}>
            <div>
                {
                    children != null
                    ? <div id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={onlyHover ? undefined : handleOpen}>
                        {children}
                      </div>
                    : <button
                        type="button"
                        className={classNames(ClassNames.Button, className)}
                        id="menu-button"
                        aria-expanded="true"
                        aria-haspopup="true"
                        onClick={onlyHover ? undefined : handleOpen}
                    >
                        {
                            multiple
                            ? <>
                                <span>{placeholder}</span>
                                {selectedSet.size > 0 && <span>({selectedSet.size})</span>}
                            </>
                            : <>
                                <span>{selectedSet.size === 0 ? placeholder : selectedItemLabel}</span>
                            </>
                        }
                        {Icons.DownChevron}
                    </button>
                }
            </div>
            <AnimatePresence mode="wait">
                {open && (
                    <>
                        {
                            !onlyHover &&
                            <div
                                className="fixed inset-0 z-10"
                                onClick={() => setOpen(false)}
                            />
                        }
                        <motion.div
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 100, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.95 }}
                            className={twMerge(classNames("absolute right-0 z-50 pt-2 w-56 origin-top-right rounded-md border-[1px] border-black/5 dark:border-white/5 dark:bg-black/50 backdrop-blur-lg dark:shadow-black shadow-lg ring-opacity-5 focus:outline-none max-h-[300px] overflow-y-scroll", dropdownClassName))}
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                            tabIndex={-1}
                        >
                            <div className="py-2" role="none">
                                {
                                    searchable &&
                                    <div className="relative group/dropdown-item px-2 mb-1">
                                        <Input value={search} setValue={setSearch} inputProps={{
                                            className: "border border-black/10 dark:border-white/10",
                                            ...inputProps,
                                        }} />
                                        <div className={classNames(ClassNames.Text, "absolute top-1/2 right-4 -translate-y-1/2")}>
                                            {cloneElement(Icons.Search, {
                                                className: "w-4 h-4",
                                            })}
                                        </div>
                                    </div>
                                }
                                {filteredItems.map(item => (
                                    <div
                                        key={item.id}
                                        className="relative group/dropdown-item"
                                    >
                                        <div
                                            className={classNames(
                                                ClassNames.Button,
                                                "block px-4 py-1 hover:pl-6 transition-all",
                                                ClassNames.Text,
                                                "text-sm",
                                            )}
                                            role="menuitem"
                                            tabIndex={-1}
                                            id={`menu-item-${item.id}`}
                                            onClick={() => handleItemClick(item)}
                                        >
                                            {item.label}
                                        </div>
                                        <div
                                            className={classNames(
                                                "absolute top-1/2 -translate-y-1/2 right-2 group-hover/dropdown-item:backdrop-blur-lg rounded-full text-neutral-300 hidden",
                                                {
                                                    "!flex": selectedSet.has(item.id),
                                                }
                                            )}
                                        >
                                            {cloneElement(Icons.CheckCircle, {
                                                className: "h-4 w-4 stroke-neutral-600 dark:stroke-neutral-400",
                                            })}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </div>
    );
};
