import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { isNumber, keys, map } from "lodash";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { cloneElement, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Supercluster from "supercluster";
import { ClassNames } from "../../components/classes";
import { Dropdown, getDropdownItem, getDropdownItems } from "../../components/dropdown";
import { Icons } from "../../components/icons";
import { InternalPage } from "../../components/page";
import { Slider } from "../../components/slider";
import { MAPBOX_TOKEN } from "../../config/constants";
import { InternalRoutes } from "../../config/routes";
import { GetBrandLocationsQuery, useGetBrandLocationsQuery, useGetBrandsQuery } from "../../generated/graphql";
import { useAppSelector } from "../../store/hooks";
import { getImageUrl, toTitleCase } from "../../utils/functions";
import { populationData, stateCoordinates } from "./locations-data";
import { Filter, getFilterConditions, useFilter } from "../../components/filter";

type BrandLocation = GetBrandLocationsQuery["BrandLocations"][0];

const activeTextClassList = [...ClassNames.ActiveText.split(" "), "bg-teal-500", "dark:bg-teal-800"];

const countryDropdownItems = getDropdownItems(["India"]);
const stateDropdownItems = getDropdownItems(keys(stateCoordinates));
const genderDropdownItems = getDropdownItems(["Male", "Female"]);

export const BrandLocations: FC = () => {
  const sector = useAppSelector(state => state.global.sector);
  const darkModeEnabled = useAppSelector(state => state.global.theme === "dark");
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const selectedLocationRef = useRef<BrandLocation>();
  const [selectedLocation, setSelectedLocation] = useState<BrandLocation>();
  const activeMarkerRef = useRef<HTMLElement>();
  const [brandSearch, setBrandSearch] = useState("");
  const [brandIds, setBrandIds] = useState<string[]>([]);
  const [country, setCountry] = useState<string[]>([countryDropdownItems[0].id]);
  const [state, setState] = useState<string[]>([]);
  const [[minPopulation, maxPopulation], setPopulation] = useState<[number, number]>([0, 10000000000]);
  const [gender, setGender] = useState<string[]>([]);
  const [showHeatMap, setShowHeatMap] = useState(false);
  const [geoJsonData, setGeoJsonData] = useState<GeoJSON.GeoJSON>({
    type: "FeatureCollection",
    features: populationData.filter(item => item.state !== "India").map(item => ({
      type: "Feature",
      properties: {
        value: item.total,
      },
      geometry: {
        type: "Point",
        coordinates: [stateCoordinates[item.state][1], stateCoordinates[item.state][0]],
      },
    }))
  });
  const filterProps = useFilter();

  useEffect(() => {
    if (mapRef.current == null) {
      return;
    }

    const populationKey = `total${gender.length === 0 ? "" : gender[0]}`;
    let data: (typeof populationData) = [];
    for (const datum of populationData) {
      const populationSize = (datum as Record<string, string | number>)[populationKey];
      const isInState = state.length === 0 || state.includes(datum.state);
      if (
        isNumber(populationSize) &&
        populationSize > minPopulation &&
        populationSize < maxPopulation &&
        isInState
      ) {
        data.push(datum);
      }
    }
    const newGeoJsonData: GeoJSON.GeoJSON = {
      type: "FeatureCollection",
      features: data.filter(item => item.state !== "India").map(item => ({
        type: "Feature",
        properties: {
          value: (item as Record<string, any>)[populationKey],
        },
        geometry: {
          type: "Point",
          coordinates: [stateCoordinates[item.state][1], stateCoordinates[item.state][0]],
        },
      })),
    };

    if (mapRef.current.getSource("population")) {
      (mapRef.current.getSource("population") as mapboxgl.GeoJSONSource).setData(newGeoJsonData);
    }

    setGeoJsonData(newGeoJsonData);
  }, [gender, maxPopulation, minPopulation, state]);

  const addActiveClass = (el: HTMLElement) => {
    activeTextClassList.forEach(className => el.classList.add(className));
  };

  const removeActiveClass = (el: HTMLElement) => {
    activeTextClassList.forEach(className => el.classList.remove(className));
  };

  const handleCancel = useCallback(() => {
    if (activeMarkerRef.current != null) {
      removeActiveClass(activeMarkerRef.current);
    }
    selectedLocationRef.current = undefined;
    setSelectedLocation(undefined);
    activeMarkerRef.current = undefined;
  }, [activeMarkerRef]);

  const handleToggleHeatMapVisibility = useCallback(() => {
    if (mapRef.current == null) {
      return;
    }
    setShowHeatMap(status => !status);
    return mapRef.current.setLayoutProperty('population-heatmap', 'visibility', showHeatMap ? 'none' : 'visible');
  }, [showHeatMap]);

  const setupCoordinates = useCallback((brandLocations: BrandLocation[] = []) => {
    if (mapRef.current != null) {
      mapRef.current.remove();
    }
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current as HTMLElement,
      accessToken: MAPBOX_TOKEN,
      center: [77.2090, 22.6139],
      zoom: 4,
    });
  
    mapRef.current.setStyle(darkModeEnabled ? "mapbox://styles/mapbox/dark-v10" : "mapbox://styles/mapbox/streets-v11");
  
    const cluster = new Supercluster({
      radius: 40,
      maxZoom: 16,
    });
  
    cluster.load(
      brandLocations.map(location => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [location.Longitude, location.Latitude]
        },
        properties: { location }
      }))
    );
  
    const markers: mapboxgl.Marker[] = [];
    const createMarker = (element: HTMLElement, coordinates: [number, number]) => {
      const marker = new mapboxgl.Marker(element).setLngLat(coordinates).addTo(mapRef.current!);
      markers.push(marker);
      return marker;
    };
  
    const clearMarkers = () => {
      markers.forEach(marker => marker.remove());
      markers.length = 0;
    };
 
    const render = () => {
      if (!mapRef.current) return;
  
      clearMarkers();
  
      const mapBounds = mapRef.current.getBounds()!;
      const bounds: GeoJSON.BBox = [
        mapBounds.getWest(),
        mapBounds.getSouth(),
        mapBounds.getEast(),
        mapBounds.getNorth(),
      ];
  
      const zoom = mapRef.current.getZoom();
      const clusters = cluster.getClusters(bounds, Math.floor(zoom));
  
      clusters.forEach(clusterData => {
        const { coordinates } = clusterData.geometry;
        const el = document.createElement("div");
        const isCluster = clusterData.properties?.cluster;
  
        if (isCluster) {
          el.className = "dark:bg-white/10 backdrop-blur-md dark:shadow-black shadow-lg rounded-full text-white text-xs h-8 w-8 flex justify-center items-center";
          el.innerText = clusterData.properties.point_count_abbreviated;
          createMarker(el, coordinates as [number, number]);
  
          el.addEventListener("click", () => {
            mapRef.current?.flyTo({
              center: {
                lat: coordinates[1],
                lng: coordinates[0],
              },
              zoom: mapRef.current.getZoom() + 2,
              offset: [0, 0],
              essential: true,
            });
          });
        } else {
          const location = clusterData.properties.location;
          el.className = classNames(
            "w-fit px-2 py-1 rounded-xl bg-white dark:bg-white/10 backdrop-blur-md dark:shadow-black shadow-lg flex justify-center items-center",
            ClassNames.Text
          );
          el.innerText = `${toTitleCase(location.Brand?.Name ?? "")}`;
          const marker = createMarker(el, coordinates as [number, number]);
  
          marker.getElement().addEventListener("click", () => {
            if (selectedLocationRef.current?.Id === location.Id) {
              selectedLocationRef.current = undefined;
              setSelectedLocation(undefined);
              removeActiveClass(el);
              activeMarkerRef.current = undefined;
            } else {
              selectedLocationRef.current = location;
              setSelectedLocation(location);
              mapRef.current?.flyTo({
                center: [location.Longitude, location.Latitude],
                zoom: 12,
                offset: [0, 0],
                essential: true,
              });
  
              if (activeMarkerRef.current != null) {
                removeActiveClass(activeMarkerRef.current);
              }
  
              addActiveClass(el);
              activeMarkerRef.current = el;
            }
          });
        }
      });
    };
    
    render();
    mapRef.current.on("moveend", render);
    mapRef.current.on("load", () => {
      if (mapRef.current == null) {
        return;
      }
      mapRef.current.addSource("population", {
        type: "geojson",
        data: geoJsonData,
      });

      mapRef.current.addLayer({
        id: "population-heatmap",
        type: "heatmap",
        source: "population",
        paint: {
          "heatmap-weight": ["interpolate", ["linear"], ["get", "value"], 0, 0, 600000, 1],
          "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 4],
          "heatmap-color": [
            "interpolate",
            ["linear"],
            ["heatmap-density"],
            0, "rgba(33, 102, 172, 0)",
            0.1, "rgba(103, 169, 207, 0.5)",
            0.3, "rgba(209, 229, 240, 0.5)",
            0.5, "rgba(253, 219, 199, 0.5)",
            0.7, "rgba(239, 138, 98, 0.5)",
            1, "rgba(178, 24, 43, 0.5)",
          ],
          "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 30, 9, 100],
          "heatmap-opacity": 0.6,
        },
      });
      mapRef.current.setLayoutProperty('population-heatmap', 'visibility', showHeatMap ? 'visible' : 'none');
    });
  }, [darkModeEnabled, geoJsonData, showHeatMap]);

  const { data: brands } = useGetBrandsQuery({
    variables: {
      sector,
      prefix: brandSearch,
    }
  });

  const { data: brandLocations } =  useGetBrandLocationsQuery({
    onCompleted(data) {
      setupCoordinates(data.BrandLocations);
    },
    variables: {
      sector,
      brandIds,
    },
  });

  const handleBrand = useCallback((itemIds: string[]) => {
    const itemsSet = new Set(itemIds);
    setBrandIds(itemIds);
    setupCoordinates(brandLocations?.BrandLocations.filter(location => itemsSet.has(location.Brand!.Id)));
  }, [brandLocations?.BrandLocations, setupCoordinates]);

  const brandsDropdownItems = useMemo(() => {
    return map(brands?.Brand ?? [], brand => getDropdownItem(brand.Id, toTitleCase(brand.Name)));
  }, [brands?.Brand]);

    const handleQuery = useCallback(() => {
        filterProps.setConditions(getFilterConditions(filterProps));
    }, [filterProps]);

  return (
    <InternalPage routes={[InternalRoutes.Dashboard, InternalRoutes.Brands.Locations]}>
      <div className="flex flex-col items-center justify-between w-full">
        <div className={classNames("flex justify-between items-center w-full", ClassNames.BottomLine)}>
          <div className={classNames(ClassNames.Title, "text-xl")}>Brand Locations</div>
            <div className="flex gap-4 justify-between items-center">
              <Filter {...filterProps} onClick={handleQuery} ignore={["brandIds"]} />
            </div>
        </div>
        <div className="flex justify-between items-end w-full mb-4">
            <div className={ClassNames.OutlinedButton} onClick={handleToggleHeatMapVisibility}>
              {showHeatMap ? Icons.Invisible : Icons.Visible}
              <div className={classNames(ClassNames.Text, "text-sm")}>{showHeatMap ? "Hide" : "Show"} Heatmap</div>
            </div>
            <div className="flex gap-4 items-center">
              <Dropdown className="text-sm" placeholder="Filtered Brands" items={brandsDropdownItems} selectedId={brandIds} onChange={handleBrand} multiple={true}
                searchable={true} inputProps={{
                  onKeyUp: e => setBrandSearch((e.target as HTMLInputElement).value),
                }} />
              <Dropdown className="text-sm" placeholder="Country" items={countryDropdownItems} selectedId={country} onChange={setCountry} />
              <Dropdown className="text-sm" placeholder="State" items={stateDropdownItems} selectedId={state} onChange={setState} multiple={true} />
              <Slider className="text-sm" placeholder="Population" min={0} max={1000000000} onChange={setPopulation} step={10000000} />
              <Dropdown className="text-sm" placeholder="Gender" items={genderDropdownItems} selectedId={gender} onChange={setGender} multiple={true} />
          </div>
        </div>
      </div>
      <div className="h-full w-full rounded-xl" ref={mapContainerRef} />
      <AnimatePresence mode="wait">
        {selectedLocation && (
          <motion.div className="dark fixed z-10 top-1/3 h-1/3 w-[min(33vw,350px)] shadow-2xl shadow-black dark:bg-black/10 dark:backdrop-blur-lg p-8 rounded-3xl"
            initial={{ opacity: 0, right: 100 }}
            animate={{ opacity: 1, right: 200 }}
            exit={{ opacity: 0, right: 100 }}
            onClick={(e) => e.stopPropagation()}>
            <div className="flex flex-col gap-4">
              <div className="flex justify-between items-start">
                <h2 className={classNames(ClassNames.Text, "text-2xl")}>{toTitleCase(selectedLocation.Name)}</h2>
                <button className={ClassNames.Button} onClick={handleCancel}>
                  {cloneElement(Icons.Cancel, {
                      className: "w-4 h-4",
                  })}
                  Close
                </button>
              </div>
              <p className={ClassNames.Text}>{toTitleCase(selectedLocation.Address)}</p>
              <p className={classNames(ClassNames.Text, "text-sm")}>Opening times: {selectedLocation.OpeningTimes}</p>
              <p className={classNames(ClassNames.Text, "text-sm")}>Contact: {selectedLocation.Contact.replace( /(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}</p>
              {selectedLocation.Images.map(image => (
                <img className="rounded-xl h-[250px] w-auto shadow-md shadow-black" alt="Product showcase" src={getImageUrl(image.Url, "lg")} />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </InternalPage>
  );
};
