import classNames from "classnames";
import { FC, useMemo } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  RectangleProps,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { toTitleCase } from "../../utils/functions";
import { ClassNames } from "../classes";
import { ExportButton, useExportButton } from "../export-button";
import { FILL_COLORS } from "./common";

type IQuartileChartProps = {
  label?: string;
  min: number;
  lowerQuartile: number;
  median: number;
  upperQuartile: number;
  max: number;
  average?: number;
  allowExport?: boolean;
  borderless?: boolean;
  hide?: ("x" | "y")[];
  yMin?: number;
  yMax?: number;
};

type BoxPlotData = {
  "Min": number;
  "Max": number;
  "Bottom Whisker": number;
  "75% Quartile": number;
  "25% Quartile": number;
  "Top Whisker": number;
  "Median"?: number;
  "Average"?: number;
};

const HorizonBar = (props: RectangleProps) => {
  const { x, y, width, height } = props;

  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  return (
    <line className="stroke-neutral-300 dark:stroke-neutral-500" x1={x} y1={y} x2={x + width} y2={y} strokeWidth={2} />
  );
};

const BORDER_RADIUS = 4;

const DotBar = (props: RectangleProps) => {
  const { x, y, width, height } = props;

  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  return (
    <line
      className="stroke-neutral-300 dark:stroke-neutral-500"
      x1={x + width / 2}
      y1={y + height}
      x2={x + width / 2}
      y2={y}
      strokeWidth={2}
      stroke-dasharray="5"
    />
  );
};

const useBoxPlot = (boxPlot: IQuartileChartProps): BoxPlotData => {
  const data = useMemo(
    () => ({
      "Max": boxPlot.max,
      "Top Whisker": boxPlot.max - boxPlot.upperQuartile,
      "75% Quartile": boxPlot.median - boxPlot.lowerQuartile,
      "Median": boxPlot.median,
      "25% Quartile": boxPlot.upperQuartile - boxPlot.median,
      "Bottom Whisker": boxPlot.lowerQuartile - boxPlot.min,
      "Min": boxPlot.min,
      "Average": boxPlot.average,
    }),
    [boxPlot]
  );

  return data;
};

export const QuartileChart: FC<IQuartileChartProps> = (props) => {
  const { ref, downloading, exportOptions } = useExportButton();
  const data = useBoxPlot(props);

  return (
    <div className={classNames("w-full min-h-[350px] relative", ClassNames.ExportButton)}>
      {props.allowExport && <ExportButton downloading={downloading} exportOptions={exportOptions} /> }
      <ResponsiveContainer className={classNames("h-full w-full", {
        "py-4 px-8": !props.borderless,
      })} ref={ref}>
        <ComposedChart data={[data]} barSize={20}>
          <CartesianGrid strokeDasharray="4 4" className="stroke-white/10" />
          <Tooltip content={<div className={ClassNames.ChartTooltip}>
              <div>Max: {props.max}</div>
              <div>75% Quartile: {props.upperQuartile.toFixed(2)}</div>
              <div>Average: {props.average?.toFixed(2)}</div>
              <div>Median: {props.median.toFixed(2)}</div>
              <div>25% Quartile: {props.lowerQuartile.toFixed(2)}</div>
              <div>Min: {props.min}</div>
            </div>}
          />
          <Bar stackId="a" dataKey="Min" fill="none" />
          <Bar stackId="a" dataKey="bar" shape={<HorizonBar />} />
          <Bar stackId="a" dataKey="Bottom Whisker" shape={<DotBar />} />
          <Bar stackId="a" className={classNames(FILL_COLORS[0], "opacity-50")} dataKey="25% Quartile" radius={[0, 0, BORDER_RADIUS, BORDER_RADIUS]} />
          <Scatter className={classNames(FILL_COLORS[1], "stroke-white/10")} dataKey="Average" />
          <Bar stackId="a" dataKey="bar" shape={<HorizonBar />} />
          <Bar stackId="a" className={classNames(FILL_COLORS[0], "opacity-50")} dataKey="75% Quartile" radius={[BORDER_RADIUS, BORDER_RADIUS, 0, 0]} />
          <Bar stackId="a" dataKey="Top Whisker" shape={<DotBar />} />
          <Bar stackId="a" dataKey="bar" shape={<HorizonBar />} />
          <Bar stackId="a" dataKey="Max" fill="none" />
          <XAxis tickFormatter={(value) => props.label != null ? toTitleCase(props.label) : value} hide={props.hide?.includes("x")} />
          <YAxis type="number" domain={(props.yMin == null || props.yMax == null) ? undefined : [props.yMin, props.yMax + 1000]} allowDataOverflow={true} hide={props.hide?.includes("y")} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
