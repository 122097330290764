import { FC } from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis, LabelList } from "recharts";
import { FILL_COLORS } from "./common";
import { toTitleCase } from "../../utils/functions";
import { ClassNames } from "../classes";
import classNames from "classnames";

export const HorizontalBarChart: FC<{ data: { label: string, value: number }[] }> = ({ data }) => {
    return (
        <ResponsiveContainer>
            <BarChart layout="vertical" data={data}>
                <XAxis type="number" />
                <YAxis type="category" dataKey="label" tickFormatter={(name) => toTitleCase(name)} />
                <Tooltip cursor={{fill: 'transparent'}} content={(data) => (<div className={classNames(ClassNames.IgnoreHTMLToImage, ClassNames.ChartTooltip)}>
                    {toTitleCase(data.label)}: {data.payload?.at(0)?.value}
                </div>)} />
                <Bar className={FILL_COLORS[0]} dataKey="value" barSize={20}>
                    <LabelList dataKey="value" position="right" className={classNames(ClassNames.Text, "text-sm")} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};
