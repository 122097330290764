import classNames from "classnames";
import { motion } from "framer-motion";
import { isArray, max, min } from "lodash";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { ClassNames } from "../../components/classes";
import { Filter, getFilterConditions, useFilter } from "../../components/filter";
import { InternalPage } from "../../components/page";
import { Table } from "../../components/table";
import { ViewControl, ViewControlType } from "../../components/view-control";
import { InternalRoutes } from "../../config/routes";
import { Product, useGetProductsLazyQuery } from "../../generated/graphql";
import { getFirstImageUrl, getImageUrl, toTitleCase } from "../../utils/functions";
import { ProductDetails } from "./product-details";

export const PAGE_SIZE = 50;

export const ProductCard: FC<{ product: Product, className?: string, onClick?: () => void }> = ({ product, className, onClick }) => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = useCallback(() => {
    setHover(true)
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div className={twMerge(classNames(ClassNames.Card, "group/product flex-col relative w-[150px] h-[250px] cursor-pointer overflow-hidden dark:bg-white/10", className))} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
      onClick={onClick}>
      {product.Brand != null &&
        <div className={twMerge(classNames(ClassNames.Card, "opacity-50 group-hover/product:opacity-100 bg-black/30 dark:bg-black/50 max-w-[140px] absolute left-2 top-2 transition-all z-10 justify-center items-center cursor-pointer px-2 py-[2px] hover:scale-110"))}
            onClick={() => navigate(InternalRoutes.Brands.Brands.path, {
                state: {
                  brand: product.Brand,
                }
            })}>
            <div className={twMerge(classNames(ClassNames.Text, "text-xs text-neutral-300"))}>{toTitleCase(product.Brand.Name)}</div>
        </div>
      }
      { getFirstImageUrl(product.SKUs) != null && <img className="w-full h-full object-cover rounded-2xl" src={getImageUrl(getFirstImageUrl(product.SKUs)!)} alt="Product" /> }
      <motion.div className="opacity-50 group-hover/product:opacity-100 absolute bottom-0 w-full h-[33%] bg-black/50 dark:bg-black/50 backdrop-blur-sm flex flex-col gap-2 px-2 py-2 group/product select-none"
        variants={{
          open: { height: "60%", },
          close: { height: "33%", },
        }}
        animate={hover ? "open" : "close"}>
        <div className={twMerge(classNames(ClassNames.Text, "text-xs text-neutral-300"))}>
          {product.Name}
        </div>
        <div className={twMerge(classNames(ClassNames.Text, "text-sm self-start text-neutral-300"))}>₹ {product.SKUs?.[0]?.Price?.toLocaleString()}</div>
        <div className={classNames("opacity-0 duration-500 flex flex-col gap-2", {
          "opacity-100": hover,
        })}>
          <button className={twMerge(classNames(ClassNames.OutlinedButton, "self-start text-xs text-neutral-300"))}>
            Explore
          </button>
        </div>
      </motion.div>
    </div>
  )
}

export const ProductPage: FC = () => {
  const [view, setView] = useState<ViewControlType>("image-sm");
  const [productToShow, setProductToShow] = useState<Product | undefined>();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [getProducts, { loading } ] = useGetProductsLazyQuery({
    onCompleted(data) {
      setProducts(p => [...p, ...data.Products as Product[] ?? []]);
    },
  });
  const filterProps = useFilter();
  
  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        getProducts({
          variables: {
            pageSize: PAGE_SIZE,
            pageOffset: products.length,
            conditions: getFilterConditions(filterProps),
          },
        });
      }
    }
  }, [getProducts, products.length, filterProps]);
  
  const handleQuery = useCallback(() => {
    const newQueryParams = getFilterConditions(filterProps);
    filterProps.setConditions(newQueryParams);
    getProducts({
      variables: {
        pageSize: PAGE_SIZE,
        pageOffset: 0,
        conditions: newQueryParams,
      },
      onCompleted(data) {
        setProducts(data.Products as Product[]);
      },
      nextFetchPolicy: "network-only",
    });
  }, [filterProps, getProducts]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    handleQuery();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rows = useMemo(() => {
    if (products.length === 0) {
      return [];
    }
    return products.map(product => {
      const skusPrice = product.SKUs.map(sku => sku.Price);
      const minPrice = min(skusPrice);
      const maxPrice = max(skusPrice);
      const colors = product.SKUs.reduce((all: Set<string>, one) => {
        (one.Images?.map(image => image.Color) ?? []).forEach(color => all.add(color));
        return all;
      }, new Set<string>());
      return [product.Name, maxPrice == null ? "Unknown" : minPrice === maxPrice ? `${maxPrice}` : `${minPrice} - ${maxPrice}`, product.Description, product.Labels, [...colors.values()]];
    });
  }, [products]);

  return (
    <InternalPage routes={[InternalRoutes.Dashboard, InternalRoutes.Product.Product]}>
      <div className="flex w-full h-full gap-20">
        <div className="flex flex-col h-[90vh] w-full gap-4">
          <div className="flex justify-between w-full">
            <div className={classNames(ClassNames.Title, "text-2xl")}>Products</div>
            <Filter {...filterProps} onClick={handleQuery} loading={loading || filterProps.loading} />
          </div>
          <div className="w-full flex justify-end">
            <ViewControl loading={loading} view={view} setView={setView} />
          </div>
          {
            view === "table" &&
            <div ref={tableRef} className="w-full h-[90vh] overflow-auto">
              <Table className="w-full" columns={["Name", "Price", "Description", "Tags", "Colors"]} currentPage={0} rows={rows} totalPages={1}
                onClickRow={index => setProductToShow(products[index])}
                customElement={{
                  3: (cell) => (
                    <div className="px-2 flex gap-1">
                      {isArray(cell.value) ? cell.value.map(label => (
                        <div className="bg-teal-500/50 px-2 py-[2px] w-fit rounded-3xl text-[10px]">{label}</div>
                      )) : null}
                    </div>
                  ),
                  4: (cell) => (
                    <div className="px-2 flex gap-1 flex-wrap overflow-y-auto">
                      {isArray(cell.value) ? cell.value.map(label => (
                        <div className="bg-teal-500/50 px-2 py-[2px] rounded-3xl text-[10px]">{label}</div>
                      )) : null}
                    </div>
                  )
                }} />
            </div>
          }
          {
            view !== "table" &&
            <div ref={containerRef} className="flex flex-wrap w-full items-center gap-4 space-y-2 content-start h-[90vh] overflow-auto" >
                {products.map((product) => (
                  <ProductCard className={classNames({
                    "w-[150px] h-[250px]": view === "image-sm",
                    "w-[22vw] h-[50vw]": view === "image-md",
                    "w-[45vw] h-[50vw]": view === "image-lg",
                  })} key={product.Id} product={product} onClick={() => setProductToShow(product)} />
                ))}
            </div>
          }
        </div>
      </div>
      {productToShow != null && productToShow.Brand != null && (
        <ProductDetails
          className="dark:bg-black/70"
          brand={productToShow.Brand}
          product={productToShow}
          onCancel={() => setProductToShow(undefined)}
        />
      )}
    </InternalPage>
  );
};